import {
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react'
import { useSelector } from 'react-redux'
import {
    useQuery,
    useDeepQuery,
} from '../../../../../hooks/useQuery'
import {
    clearNullishValues,
    isEmptyObject,
    omitBy,
    isEven,
} from '../../../../../utils'
import uniq from 'lodash.uniq'

export const extractFiltersFromQuery = (query) => {
    return Object.entries(
        omitBy(
            query,
            (value, key) =>
                !key.startsWith('filters') || !value,
        ),
    ).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value.split('~'),
        }),
        {},
    )
}

export const mergeFiltersFromQueryAndState = (
    query = {},
    state = {},
) =>
    ((q = extractFiltersFromQuery(query), s = state) =>
        uniq([...Object.keys(s), ...Object.keys(q)])
            .map((key) => [
                key,
                [...(s[key] || []), ...(q[key] || [])],
            ])
            .reduce(
                (acc, [key, values]) => ({
                    ...acc,
                    [key]: values.reduce(
                        (vs, val) =>
                            isEven(
                                values.filter(
                                    (v) => v === val,
                                ).length,
                            ) || vs.includes(val)
                                ? vs
                                : [...vs, val],
                        [],
                    ),
                }),
                {},
            ))()

export const useFilters = (isMobile) => {
    const [query, setQuery, TrackingTypes] = useDeepQuery()

    const [filtersState, setFiltersState] = useState({})

    const activeFilters = useMemo(
        () =>
            mergeFiltersFromQueryAndState(
                query,
                filtersState,
            ),
        [filtersState, query],
    )

    const apply = useCallback(() => {
        if (!(isMobile && isEmptyObject(filtersState))) {
            setQuery(
                {
                    ...query,
                    ...Object.keys(activeFilters).reduce(
                        (acc, key) => ({
                            ...acc,
                            [key]: activeFilters[key].join(
                                '~',
                            ),
                        }),
                        {},
                    ),
                    offset: 0,
                },
                TrackingTypes.CLICK_INTERNAL_FILTER,
            )
        }
        setFiltersState({})
    }, [
        isMobile,
        filtersState,
        activeFilters,
        TrackingTypes.CLICK_INTERNAL_FILTER,
        setQuery,
        query,
    ])

    useEffect(() => {
        !(isMobile || isEmptyObject(filtersState)) &&
            apply()
    }, [activeFilters, isMobile, filtersState, apply])

    const setFilter = (filter, option) => {
        setFiltersState({
            [filter.param]: [
                ...(filtersState[filter.param] || []),
                option,
            ],
        })
    }

    const getSelectedOptions = (filter) =>
        activeFilters[filter.param] || []

    const clearFilters = () => {
        setQuery(
            omitBy(
                query,
                (_, key) =>
                    key.includes('filter') ||
                    key.includes('offset'),
            ),
            TrackingTypes.CLICK_INTERNAL_FILTER,
        )

        setFiltersState({})
    }

    return [
        setFilter,
        getSelectedOptions,
        clearFilters,
        activeFilters,
        apply,
    ]
}

export const useSorting = (sortings) => {
    const [query, setQuery, TrackingTypes] = useQuery()

    const getSelectedOption = () => {
        return (
            sortings.find(
                (sorting) =>
                    sorting.order === query['order'],
            ) || sortings[0]
        )
    }

    const setSorting = (order, value) => {
        setQuery(
            clearNullishValues({
                ...query,
                order,
                sort: value,
                offset: 0,
            }),
            TrackingTypes.CLICK_INTERNAL,
        )
    }

    return [setSorting, getSelectedOption]
}

export const useGenderFilter = () => {
    const [query, setQuery, TrackingTypes] = useQuery()

    const getSelection = () => {
        return (query['filters.gender'] || '')
            .split('~')
            .filter(Boolean)
    }

    const setFilter = (option) => {
        if (getSelection().includes(option)) {
            setQuery(
                clearNullishValues({
                    ...query,
                    offset: 0,
                    'filters.gender': null,
                }),
                TrackingTypes.CLICK_INTERNAL_FILTER,
            )
        } else {
            setQuery(
                clearNullishValues({
                    ...query,
                    offset: 0,
                    'filters.gender': `${option}~unisex`,
                }),
                TrackingTypes.CLICK_INTERNAL_FILTER,
            )
        }
    }

    return [setFilter, getSelection]
}

export const usePriceFilterQuery = () => {
    const { max: maxRange, min: minRange } = useSelector(
        (state) =>
            state.filters.find((f) => f.name === 'price'),
    )
    const [query, setQuery, TrackingTypes] = useQuery()

    const [queryMin, queryMax] = query['filters.price']
        ?.split('-')
        .map((price) => parseInt(price)) ?? [
        0,
        Number.MAX_SAFE_INTEGER,
    ]

    const isRangeSelected =
        queryMin !== 0 ||
        queryMax !== Number.MAX_SAFE_INTEGER

    const setQueryPriceRange = (min, max) => {
        const isDefaultRange =
            min === 0 && max === Number.MAX_SAFE_INTEGER
        setQuery(
            clearNullishValues({
                ...query,
                offset: 0,
                'filters.price': isDefaultRange
                    ? null
                    : `${min}-${max}`,
            }),
            TrackingTypes.CLICK_INTERNAL_FILTER,
        )
    }

    const resetQueryRange = () => {
        setQueryPriceRange(0, Number.MAX_SAFE_INTEGER)
    }

    return {
        isRangeSelected,
        queryMin,
        queryMax,
        maxRange,
        minRange,
        setQueryPriceRange,
        resetQueryRange,
        displayMin: Math.max(queryMin, minRange),
        displayMax: Math.min(queryMax, maxRange),
    }
}

export const usePriceFilter = () => {
    const {
        isRangeSelected,
        maxRange,
        minRange,
        queryMax,
        queryMin,
        resetQueryRange,
        setQueryPriceRange,
    } = usePriceFilterQuery()

    const [priceRange, setPriceRange] = useState({
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
    })

    useEffect(() => {
        setPriceRange({ min: queryMin, max: queryMax })
    }, [queryMin, queryMax])

    const applyRange = () => {
        setQueryPriceRange(
            priceRange.min === minRange
                ? 0
                : priceRange.min,
            priceRange.max === maxRange
                ? Number.MAX_SAFE_INTEGER
                : priceRange.max,
        )
    }
    const resetRange = () => {
        resetQueryRange()
        setPriceRange({
            min: 0,
            max: Number.MAX_SAFE_INTEGER,
        })
    }

    const handleRangeChange = (e, range) => {
        e.preventDefault()
        const step = 5
        if (range === 'min') {
            const selectedMin = e.target.value
                ? parseInt(e.target.value)
                : minRange
            const newMinVal = Math.min(
                selectedMin,
                priceRange.max - step,
            )
            setPriceRange((prev) => ({
                ...prev,
                min: newMinVal,
            }))
        } else if (range === 'max') {
            const selectedMax = e.target.value
                ? parseInt(e.target.value)
                : maxRange
            const newMaxVal = Math.max(
                selectedMax,
                priceRange.min + step,
            )
            setPriceRange((prev) => ({
                ...prev,
                max: newMaxVal,
            }))
        }
    }
    return {
        isRangeSelected,
        priceRange,
        maxRange,
        minRange,
        queryMin,
        queryMax,
        setPriceRange,
        setQueryPriceRange,
        resetRange,
        applyRange,
        handleRangeChange,
        displayMin: Math.max(priceRange.min, minRange),
        displayMax: Math.min(priceRange.max, maxRange),
    }
}
