export const mapAdvancedBreakpoints = (fn, theme) => {
    const { breakpoints, maxWidth } = theme
    return breakpoints
        .map(({ name: bpKey, value }, index) => {
            const minW = value
            const maxW = maxWidth[bpKey]

            if (bpKey === 'xs') {
                return fn(bpKey, maxW, index)
            }

            const m = fn(bpKey, maxW, index)
            return (
                m &&
                `@media (min-width: ${minW}) {
        ${m}
      }`
            )
        })
        .join(' ')
}

// theme helpers

export const getGutter = (theme, type, reverse) => {
    if (theme?.grid?.gutter) {
        const px = `${theme.grid.gutter}px`

        if (type === 'padding') {
            return `
        padding-left: ${px};
        padding-right: ${px};
      `
        }

        if (type === 'margin') {
            return `
        margin-left: ${reverse ? '-' : ''}${px};
        margin-right: ${reverse ? '-' : ''}${px};
      `
        }
    }
}

// TODO: extend!
export const extractStyleProps = (props) => {
    const translationStyleProps = {
        position: 'position',
        left: 'left',
        right: 'right',
        height: 'height',
        width: 'width',
        zIndex: 'z-index',
        justifyContent: 'justify-content',
        alignItems: 'align-items',
        display: 'display',
    }

    return Object.keys(props).reduce((acc, key) => {
        if (translationStyleProps[key]) {
            acc[`${translationStyleProps[key]}`] =
                props[key]
        }
        return acc
    }, {})
}

export const getStyles =
    (paths = []) =>
    (props) => {
        const styles = paths.reduce((acc, path) => {
            const p =
                typeof path === 'string'
                    ? path.split('.')
                    : path
            if (!p) {
                return acc
            }
            const o = p.reduce((acc, val) => {
                return acc && acc[val]
            }, props.theme)

            return [...acc, o?.styles]
        }, [])

        return mapAdvancedBreakpoints((name, _, index) => {
            const p0 =
                props[name] ||
                (props.styles && props.styles[index]) ||
                ''
            const p1 = (props.classes || []).reduce(
                (acc, cl) => {
                    return acc.concat(
                        props.theme.byClasses?.[cl]?.styles[
                            index
                        ] || '',
                    )
                },
                '',
            )
            const p2 = styles.map((s) => s?.[index] || '')
            const p3 = isNaN(Number(p0)) ? p0 : ''

            return p3.concat(p1).concat(...p2)
        }, props.theme)
    }
