export const sorting = (state = [], action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            return [
                {
                    label: action.payload.model.translation
                        .sorting.relevance,
                },
                {
                    label: action.payload.model.translation
                        .sorting.priceAsc,
                    value: 'price',
                    order: 'asc',
                },
                {
                    label: action.payload.model.translation
                        .sorting.priceDesc,
                    value: 'price',
                    order: 'desc',
                },
            ]
        default:
            return state
    }
}
