import {
    createStore,
    combineReducers,
    compose,
    applyMiddleware,
} from 'redux'
import { thunk } from 'redux-thunk'

import reducers from './reducers'

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? /* istanbul ignore next */
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose

const enhancer = composeEnhancers(applyMiddleware(thunk))

const combinedReducers = combineReducers(reducers)

export default function getStore(preloadedState = {}) {
    return createStore(
        combinedReducers,
        preloadedState,
        enhancer,
    )
}
