import { defaultTheme } from './defaultTheme'
import { getTheme, mergeThemes } from './modules'

/* eslint-disable no-case-declarations */
export const theme = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT': {
            const assets =
                action.payload.model.template.assets
            const customTheme = {
                ...action.payload.model.template.theme,
                variables: {
                    ...action.payload.model.template.theme
                        .variables,
                    assets: {
                        ...(action.payload.model.template
                            .theme.variables?.assets || {}),
                        ...assets,
                    },
                },
            }

            const theme = mergeThemes(
                JSON.parse(JSON.stringify(defaultTheme)),
                customTheme,
            )

            return getTheme(theme)
        }

        case 'UPDATE_TEMPLATE': {
            const assets =
                action.payload.model.template.assets
            const customTheme = {
                ...action.payload.model.template.theme,
                variables: {
                    ...action.payload.model.template.theme
                        .variables,
                    assets: {
                        ...(action.payload.model.template
                            .theme.variables?.assets || {}),
                        ...assets,
                    },
                },
            }

            const theme = mergeThemes(
                JSON.parse(JSON.stringify(defaultTheme)),
                customTheme,
            )

            return getTheme(theme)
        }
        default:
            return state
    }
}
