import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useExternalNavigate } from '../../../hooks/useExternalNavigate'
import { getStyles } from '@conversionbuddy/Layout'

const StyledFooterLinksContainer = styled.div`
    ${getStyles(['footer.links.container'])}
`

const StyledFooterLink = styled.a`
    ${getStyles(['footer.link'])}
`

export const FooterLinks = () => {
    const [navigate, TrackingTypes] = useExternalNavigate()
    const links = useSelector((state) => state.links.footer)
    const renderLinks = () =>
        links.map((link) => {
            const handleClick = (e) => {
                e.preventDefault()
                navigate(link.url, {
                    type: TrackingTypes.CROSS_OUT_INFO,
                })
            }

            return (
                <StyledFooterLink
                    key={link.name}
                    href={link.url}
                    onClick={handleClick}
                >
                    {link.name}
                </StyledFooterLink>
            )
        })

    return (
        <StyledFooterLinksContainer>
            {renderLinks()}
        </StyledFooterLinksContainer>
    )
}
