/* istanbul ignore file */
import { useState } from 'react'

export const useTouch = () => {
    const [touched, setTouched] = useState(false)

    return [
        touched,
        {
            onTouchStart: () => setTouched(true),
            onTouchEnd: () => setTouched(false),
        },
    ]
}
