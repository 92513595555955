import React from 'react'
import styled from 'styled-components'

import { mapAdvancedBreakpoints } from '../utils'

const A = styled.a`
    color: inherit;
    height: 100%;
    width: 100%;
    display: block;
    text-decoration: none;

    &:hover,
    &:visited,
    &:active {
        text-decoration: none;
    }

    ${(props) => {
        return mapAdvancedBreakpoints((name, _, index) => {
            const prop =
                props[name] ||
                (props.styles && props.styles[index])

            if (prop) {
                return prop
            }
        }, props.theme)
    }}
`

export const Link = ({ href, children, ...other }) => (
    <A
        href={href}
        {...other}
    >
        {children}
    </A>
)
