import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '../Box'

export const Image = ({
    label,
    onClick,
    position,
    show,
    src,
}) => {
    const styles = [
        `
        position: ${position};
        top: 0;
        ${src && `background-image: url(${src});`}
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    `,
    ]
    return show ? (
        <Box
            aria-label={label}
            draggable={false}
            role='img'
            styles={styles}
            onClick={onClick}
        />
    ) : null
}

Image.defaultProps = { position: 'relative' }

Image.propTypes = {
    label: PropTypes.string.isRequired,
    src: PropTypes.string,
    position: PropTypes.string,
    show: PropTypes.bool,
    onClick: PropTypes.func,
}
