import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useExternalNavigate } from '../../../../hooks/useExternalNavigate'
import { formatPrice } from '../../../../utils'
import { getStyles } from '@conversionbuddy/Layout'
import { useTranslation } from '../../../../hooks/useTranslation'

const StyledLink = styled.a`
    color: inherit;
`

const PriceInfoContainer = styled.div`
    ${getStyles(['productLayer.priceInfo.container'])}
`

const Link = ({ children, href }) => {
    const [navigate, TrackingTypes] = useExternalNavigate()

    const handleClick = (e) => {
        e.preventDefault()
        navigate(href, {
            type: TrackingTypes.CLICK_OUT_INFO,
        })
    }
    return (
        <StyledLink
            href={href}
            onClick={handleClick}
        >
            {children}
        </StyledLink>
    )
}

Link.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
}

export const PriceInfo = () => {
    const shippingCost = useSelector((state) => {
        const cost = state.product.skus[0].shippingCost
        const config = state.config?.currency
        const visible =
            !state.general?.hideLayerShippingCost

        if (cost > 0 && !visible) {
            return null
        }

        return cost && formatPrice(cost, config)
    })
    const links = useSelector((state) => state.links)
    const translationCallback = (name, value) => {
        const link = links[name]?.url
        return link ? (
            <Link
                key={name}
                href={link}
            >
                {value}
            </Link>
        ) : (
            <span key={name}>{value}</span>
        )
    }

    const nonFreePhrase = useTranslation(
        'priceInfos.layer.nonFree',
        {
            shippingCost,
        },
        translationCallback,
    )

    const freePhrase = useTranslation(
        'priceInfos.layer.free',
        null,
        translationCallback,
    )

    return (
        <PriceInfoContainer>
            {shippingCost === null || shippingCost
                ? nonFreePhrase
                : freePhrase}
        </PriceInfoContainer>
    )
}
