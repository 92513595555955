import React from 'react'

export const Caret = ({ color, direction }) => {
    const width = 24
    const height = 24

    const getRotation = () => {
        const mid = [width / 2, height / 2]

        const angle = {
            left: '90',
            bottom: '0',
            right: '-90',
            top: '180',
        }

        return `rotate(${angle[direction] || 0}, ${
            mid[0]
        }, ${mid[1]})`
    }

    return (
        <svg
            width='100%'
            height='100%'
            viewBox={`0 0 ${width} ${height}`}
        >
            <path
                d='m24,7.24918q0,0.609 -0.445,1.055l-10.5,10.5q-0.445,0.445 -1.055,0.445t-1.055,-0.445l-10.5,-10.5q-0.445,-0.445 -0.445,-1.055t0.445,-1.055t1.055,-0.445l21,0q0.609,0 1.055,0.445t0.445,1.055z'
                fill={color}
                transform={getRotation()}
            />
        </svg>
    )
}

Caret.defaultProps = {
    color: '#000000',
}

export const Info = ({ color }) => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox={`0 0 36 42`}
        >
            <path
                fill='#FFFFFF'
                d='M24 32.25v-3.75q0-0.328-0.211-0.539t-0.539-0.211h-2.25v-12q0-0.328-0.211-0.539t-0.539-0.211h-7.5q-0.328 0-0.539 0.211t-0.211 0.539v3.75q0 0.328 0.211 0.539t0.539 0.211h2.25v7.5h-2.25q-0.328 0-0.539 0.211t-0.211 0.539v3.75q0 0.328 0.211 0.539t0.539 0.211h10.5q0.328 0 0.539-0.211t0.211-0.539zM21 11.25v-3.75q0-0.328-0.211-0.539t-0.539-0.211h-4.5q-0.328 0-0.539 0.211t-0.211 0.539v3.75q0 0.328 0.211 0.539t0.539 0.211h4.5q0.328 0 0.539-0.211t0.211-0.539zM36 21q0 4.898-2.414 9.035t-6.551 6.551-9.035 2.414-9.035-2.414-6.551-6.551-2.414-9.035 2.414-9.035 6.551-6.551 9.035-2.414 9.035 2.414 6.551 6.551 2.414 9.035z'
            />
        </svg>
    )
}

export const ShoppingCart = ({ color }) => {
    return (
        <svg
            width='100%'
            height='100%'
            viewBox={`0 0 39 42`}
        >
            <path
                fill='#ffffff'
                d='M15 36q0 1.219-0.891 2.109t-2.109 0.891-2.109-0.891-0.891-2.109 0.891-2.109 2.109-0.891 2.109 0.891 0.891 2.109zM36 36q0 1.219-0.891 2.109t-2.109 0.891-2.109-0.891-0.891-2.109 0.891-2.109 2.109-0.891 2.109 0.891 0.891 2.109zM39 10.5v12q0 0.562-0.387 0.996t-0.949 0.504l-24.469 2.859q0.305 1.406 0.305 1.641 0 0.375-0.562 1.5h21.562q0.609 0 1.055 0.445t0.445 1.055-0.445 1.055-1.055 0.445h-24q-0.609 0-1.055-0.445t-0.445-1.055q0-0.258 0.187-0.738t0.375-0.844 0.504-0.937 0.363-0.691l-4.148-19.289h-4.781q-0.609 0-1.055-0.445t-0.445-1.055 0.445-1.055 1.055-0.445h6q0.375 0 0.668 0.152t0.457 0.363 0.305 0.574 0.187 0.609 0.129 0.691 0.105 0.609h28.148q0.609 0 1.055 0.445t0.445 1.055z'
            />
        </svg>
    )
}

export const Cross = ({ color, strokeWidth }) => {
    const s = strokeWidth || 4
    return (
        <svg viewBox='0 0 22.88 22.88'>
            <path
                fill={color}
                d='M0.324,1.909c-0.429-0.429-0.429-1.143,0-1.587c0.444-0.429,1.143-0.429,1.587,0l9.523,9.539
	l9.539-9.539c0.429-0.429,1.143-0.429,1.571,0c0.444,0.444,0.444,1.159,0,1.587l-9.523,9.524l9.523,9.539
	c0.444,0.429,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-9.539-9.539l-9.523,9.539c-0.444,0.429-1.143,0.429-1.587,0
	c-0.429-0.444-0.429-1.159,0-1.587l9.523-9.539L0.324,1.909z'
                strokeWidth={s}
                stroke={color}
            />
        </svg>
    )
}

export const Menu = ({ color }) => {
    return (
        <svg
            viewBox='0 0 20 17'
            fill='none'
            stroke={color}
            strokeWidth='3'
        >
            <path d='M0 8.5h20M0 15.4h20M0 1.5h20' />
        </svg>
    )
}

export const Arrow = ({ color, direction }) => {
    const getRotation = () => {
        if (direction === 'top') {
            return 'none'
        }

        if (direction === 'right') {
            return 'rotate(90)'
        }

        if (direction === 'left') {
            return 'rotate(270)'
        }

        if (direction === 'bottom') {
            return 'rotate(180)'
        }
    }
    return (
        <svg
            height='17'
            transform={getRotation()}
            viewBox='0 0 30 17'
        >
            <path
                d='m0.76622,15.60842l14.26165,-14.40602l14.22814,14.22814'
                fill='none'
                stroke={color}
            />
        </svg>
    )
}

export const Reset = ({ color }) => {
    return (
        <svg viewBox='0 0 256 256'>
            <g transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'>
                <path
                    d='M 75.702 53.014 c -2.142 7.995 -7.27 14.678 -14.439 18.816 c -7.168 4.138 -15.519 5.239 -23.514 3.095 c -16.505 -4.423 -26.335 -21.448 -21.913 -37.953 C 20.258 20.467 37.286 10.64 53.79 15.06 c 4.213 1.129 8.076 3.118 11.413 5.809 l -8.349 8.35 h 26.654 V 2.565 l -8.354 8.354 c -5.1 -4.405 -11.133 -7.61 -17.74 -9.381 C 33.451 -4.882 8.735 9.389 2.314 33.35 c -6.42 23.961 7.851 48.678 31.811 55.098 C 38.001 89.486 41.934 90 45.842 90 c 7.795 0 15.488 -2.044 22.42 -6.046 c 10.407 -6.008 17.851 -15.709 20.962 -27.317 L 75.702 53.014 z'
                    transform=' matrix(1 0 0 1 0 0) '
                    stroke={color}
                />
            </g>
        </svg>
    )
}

export const Filter = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
        >
            <path d='M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z' />
        </svg>
    )
}

export const Search = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            stroke='#000000'
            fill='none'
        >
            <path
                stroke='none'
                d='M0 0h24v24H0z'
                fill='none'
            ></path>
            <path d='M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0'></path>
            <path d='M21 21l-6 -6'></path>
        </svg>
    )
}
