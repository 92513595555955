import mergeWith from 'lodash.mergewith'
import uniq from 'lodash.uniq'
import { cssProps } from '../../../../utils/cssProps'
import { combine, times } from '../../../../utils'

const compose =
    (...functions) =>
    (x) =>
        functions.reduceRight((acc, fn) => fn(acc), x)

export const mergeThemes = (t0, t1) => {
    return mergeWith(t0, t1, (source, target, key) => {
        if (key === 'styles') {
            return mergeStylesList(source, target)
        }

        if (key === 'breakpoints') {
            if (target) {
                if (Array.isArray(target)) {
                    return target
                }

                // allow deprecated breakpoints data structure
                return Object.keys(target).map(
                    (key) => target[key],
                )
            }
            return source
        }
    })
}

// TODO: refactoring
export const applyPseudoStyles = (
    s = {},
    styles = s.styles,
) => {
    return Object.keys(s)
        .filter((k) => k.startsWith('&'))
        .reduce((allStyles, pseudoKey) => {
            const pseudoStyles = applyPseudoStyles(
                s[pseudoKey],
            )
            const n = Math.max(
                allStyles.length,
                pseudoStyles.length,
            )
            return times(n, (i) => {
                const nonPseudoStyle = allStyles[i] || ''
                const pseudoStyle = pseudoStyles[i] || ''
                return pseudoStyle
                    ? nonPseudoStyle.concat(
                          `${pseudoKey} { ${pseudoStyle}}`,
                      )
                    : nonPseudoStyle
            })
        }, styles)
}

export const resolveTheme = ({
    fonts,
    icons,
    variables,
    ...t
}) => {
    const variablesList =
        transformVariablesToFlatList(variables)
    const format = compose(
        stringifyStyle,
        applyVariables(variablesList),
    )

    // handle fonts
    const styledFonts =
        fonts?.map((font) => {
            return {
                ...font,
                url: replaceInnerValue(
                    variablesList,
                    font.url,
                ),
            }
        }) || []
    // handle icons
    const styledIcons = (
        icons?.map((icon) => {
            const url = replaceInnerValue(
                variablesList,
                icon.url,
            )
            return url
                ? {
                      ...icon,
                      url,
                  }
                : null
        }) || []
    ).filter(Boolean)

    return mergeWith(
        {
            variables,
            fonts: styledFonts,
            icons: styledIcons,
        },
        t,
        (_, target, key) => {
            return key === 'styles'
                ? target.map(format)
                : undefined
        },
    )
}

export const getTheme = (theme) => {
    return mergeWith(
        {},
        resolveTheme(theme),
        (_, target) => {
            if (target.styles) {
                return {
                    ...target,
                    styles: applyPseudoStyles(target),
                }
            }
        },
    )
}

export const mergeStylesList = (l0, l1) =>
    combine(l0, l1).map((pair) => mergeStyles(...pair))

export const mergeStyles = (s0, s1) =>
    s0 || s1
        ? {
              values: {
                  ...(s0?.values || {}),
                  ...(s1?.values || {}),
              },
              order: mergeStylesOrder(s1?.order, s0?.order),
          }
        : null

const mergeStylesOrder = (a = [], b = []) =>
    uniq([...b, ...a].reverse()).reverse()

export const stringifyStyle = (style) =>
    style
        ? style.order
              .reduce(
                  (str, cssKey) =>
                      `${str} ${cssProps[cssKey]}: ${style.values[cssKey]};`,
                  '',
              )
              .trim()
        : null

export const transformVariablesToFlatList = (
    obj = {},
    path = '',
    result = [],
    prefix = '$',
) =>
    obj
        ? Object.entries(obj).reduce(
              (acc, [key, value]) => {
                  const nextPath = path
                      ? [path, key].join('.')
                      : `${prefix}${key}`
                  if (typeof value === 'object') {
                      return [
                          ...acc,
                          ...transformVariablesToFlatList(
                              value,
                              nextPath,
                              result,
                          ),
                      ]
                  }

                  return [
                      ...acc,
                      {
                          key: nextPath,
                          value,
                      },
                  ]
              },
              result,
          )
        : []

const replaceInnerValue = (
    variablesList,
    param,
    regex = /\$([0-9a-zA-Z]+\.)*([0-9a-zA-Z]+)/gm,
    matches = param.match(regex) || [],
) => {
    const match = matches.reduce(
        (acc, match) =>
            acc.replace(
                match,
                variablesList.find(
                    ({ key }) => match === key,
                )?.value || match,
            ),
        param.slice(),
    )
    return match.startsWith('$') ? null : match
}

export const applyVariables = (variablesList) => {
    return (style) => {
        if (!style) {
            return null
        }

        const newValues = Object.entries(
            style.values,
        ).reduce((acc, [styleKey, styleValue]) => {
            return {
                ...acc,
                [styleKey]: replaceInnerValue(
                    variablesList,
                    styleValue,
                ),
            }
        }, {})
        return {
            ...style,
            values: newValues,
        }
    }
}
