import React from 'react'

export const Tap = () => {
    return (
        <svg
            height='69.1px'
            viewBox='0 0 44.1 69.1'
            width='44.1px'
            x='0px'
            y='0px'
        >
            <defs></defs>
            <g>
                <path
                    d='M19,36.7V12.8c0-2.6-1.3-4.4-4.7-4.4c-3.4,0-4.7,1.8-4.7,4.4v35.4'
                    fill='none'
                    stroke='#f5f5f5'
                    strokeMiterlimit='10'
                    strokeWidth='2'
                />
                <path
                    d='M36.2,40.5v-8.6c0-3.6-0.9-6-4.1-6.3c-2.7-0.3-4.1,0.8-4.1,0.8'
                    fill='none'
                    stroke='#f5f5f5'
                    strokeMiterlimit='10'
                    strokeWidth='2'
                />
                <path
                    d='M36.2,30.5c4.8-0.8,6.3,1.8,6.5,6c0.3,4.2,0.4,6.4,0.4,11.7c0,12.7-7,20-7,20'
                    fill='none'
                    stroke='#f5f5f5'
                    strokeMiterlimit='10'
                    strokeWidth='2'
                />
                <path
                    d='M9.1,33.4c-0.5-0.2-2-0.3-3-0.3c-2.4,0-4.2,1.9-4.5,5.2c0,0-0.5,3.5-0.5,8.1c-0.1,5.9,1,8.6,3.3,12
		c3.2,4.7,10,10,10,10'
                    fill='none'
                    stroke='#f5f5f5'
                    strokeMiterlimit='10'
                    strokeWidth='2'
                />
                <path
                    d='M28.4,37.6v-8c0-3.6-1-6.9-4.7-6.9c-3.2,0-4.7,0.8-4.7,0.8'
                    fill='none'
                    stroke='#f5f5f5'
                    strokeMiterlimit='10'
                    strokeWidth='2'
                />
            </g>
        </svg>
    )
}
