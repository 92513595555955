import { formatPrice } from '../utils'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

export const getCheapestVariant = (product) => {
    return [...product.skus].sort(
        (a, b) => a.price - b.price,
    )[0]
}

const hasMultiplePrices = (product) => {
    const firstPrice = product.skus[0].price
    return !product.skus.reduce(
        (acc, sku) => acc && firstPrice === sku.price,
        true,
    )
}

const getPricePrefix = (product) => {
    return product.skus?.[0].pricePrefix ?? null
}

const getPriceInformation = (product) => {
    return product.skus?.[0].priceInformation ?? null
}

const selectProduct = (state) => state.product
const selectActiveSku = (state) => state.activeSku
const selectConfig = (state) => state.config
const selectContextProduct = (_, product) => product

const select = createSelector(
    [
        selectProduct,
        selectActiveSku,
        selectConfig,
        selectContextProduct,
    ],
    (product, activeSku, config, contextProduct) => ({
        p: contextProduct || product,
        v: contextProduct ? null : activeSku,
        config: config?.currency,
    }),
)

export const usePrices = (product) => {
    // wenn kein product übergeben -> nutze product und activeSku aus redux-store

    const { config, p, v } = useSelector((state) =>
        select(state, product),
    )

    const variant = v || getCheapestVariant(p)
    const pricePrefix = getPricePrefix(p)
    const priceInfo = getPriceInformation(p)
    const current = formatPrice(variant.price, config)
    const old = formatPrice(variant.oldPrice, config)
    const basePrice = variant.basePrice
        ? formatPrice(variant.basePrice, config)
        : null
    const lowestPrice = variant.lowestPrice
        ? formatPrice(variant.lowestPrice, config)
        : null
    const discount =
        variant.oldPrice &&
        1 - variant.price / variant.oldPrice
    const mult = hasMultiplePrices(p) && !v

    return {
        current,
        old,
        sale: !!old,
        discount,
        multiple: mult,
        multiplePrefix: config.multiplePrefix,
        basePrice,
        basePriceUnit: variant.basePriceUnit,
        lowestPrice,
        pricePrefix,
        priceInfo,
    }
}

export const useBacePrices = (product) => {}
