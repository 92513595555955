import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { renderer } from '../../utils/react'
import {
    Container,
    Column,
    Row,
    Box,
    Span,
} from '@conversionbuddy/Layout'

// children
import { FooterLinks } from './Links'
import { PriceInfo } from './PriceInfo'
import { CustomStaticLink } from '../Layout/StaticLink'
import { Scheduler } from '../Layout/Scheduler'

const FooterContainer = styled(Container)`
    margin-top: 50px;
    margin-bottom: 50px;
`

export const componentMap = {
    Container: Container,
    Box: Box,
    Fragment: React.Fragment,
    Column: Column,
    Row: Row,
    CustomStaticLink: CustomStaticLink,
    Span: Span,
    Scheduler: Scheduler,
}

export const Footer = () => {
    const footerTemplates = useSelector(
        (state) => state.template.footer,
    )

    const renderedFooterTemplates = Object.keys(
        footerTemplates,
    ).reduce((templates, position) => {
        return {
            ...templates,
            [position]: renderer(
                componentMap,
                footerTemplates[position],
            ),
        }
    }, {})

    const renderFooterTemplate = (position) => {
        return renderedFooterTemplates[position] || null
    }
    return (
        <FooterContainer>
            {renderFooterTemplate('start')}
            <PriceInfo />
            {renderFooterTemplate('between')}
            <FooterLinks />
            {renderFooterTemplate('end')}
        </FooterContainer>
    )
}
