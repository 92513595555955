import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getStyles } from '@conversionbuddy/Layout'
import { usePromotion } from '../../../hooks/usePromotion'
import { usePrices } from '../../../hooks/usePrices'
import { useTranslation } from '../../../hooks/useTranslation'
import { useSelector } from 'react-redux'

// utils
export const hasFreeShipping = (product) =>
    product.skus.reduce((acc, sku) => {
        if (!acc) return acc
        return sku.shippingCost == 0
    }, true)

const StyledEyecatchersContainer = styled.div`
    ${getStyles(['eyecatchers.container'])}
`

const StyledEyecatcher = styled.div`
    ${(props) => {
        return getStyles([
            'eyecatchers.item.default',
            `eyecatchers.item.${props.type}`,
            props.discountDisplayType === 'prices'
                ? 'eyecatchers.item.saleInPrice'
                : '',
            props.discountDisplayType === 'prices' &&
            props.layer
                ? 'eyecatchers.item.saleInPrice.layer'
                : '',
        ])(props)
    }}
`

const ShippingEyecatcher = ({ freeShipping }) => {
    const text = useTranslation('eyecatchers.shipping')

    return freeShipping ? (
        <StyledEyecatcher type='shipping'>
            {text}
        </StyledEyecatcher>
    ) : null
}

ShippingEyecatcher.propTypes = {
    freeShipping: PropTypes.bool,
}

ShippingEyecatcher.defaultProps = {
    freeShipping: false,
}

export const PromotionEyecatcher = ({ promotion }) => {
    const text = useTranslation('eyecatchers.voucher', {
        feedText: promotion?.[0].text?.split('<br>')?.[0],
    })
    return promotion ? (
        <StyledEyecatcher type='promotion'>
            {text}
        </StyledEyecatcher>
    ) : null
}

PromotionEyecatcher.propTypes = {
    promotion: PropTypes.any,
}

PromotionEyecatcher.defaultProps = {
    promotion: null,
}

export const SaleEyecatcher = ({
    discount,
    discountDisplayType,
    layer,
    multiple,
}) => {
    const prefix = useTranslation(
        'eyecatchers.sale.multiplePrefix',
    )

    return discount ? (
        <StyledEyecatcher
            discountDisplayType={discountDisplayType}
            layer={layer}
            type='sale'
        >
            {multiple ? `${prefix} ` : ''}-{discount}%
        </StyledEyecatcher>
    ) : null
}

SaleEyecatcher.propTypes = {
    discount: PropTypes.any,
    layer: PropTypes.bool,
    multiple: PropTypes.bool,
    discountDisplayType: PropTypes.string,
}

SaleEyecatcher.defaultProps = {
    discount: 0.0,
    layer: false,
    multiple: false,
    discountDisplayType: 'prices',
}

export const Eyecatchers = ({ layer, product }) => {
    const { discount, multiple } = usePrices(
        !layer && product,
    )
    const discountDisplayType = useSelector((state) => {
        return state.general.eyecatchers.discountDisplayType
    })

    const discountValue =
        discount && (discount * 100).toFixed(0)
    const promotion = usePromotion(product)
    const freeShipping = hasFreeShipping(product)

    return (
        <StyledEyecatchersContainer>
            {discountDisplayType === 'productImage' ? (
                <SaleEyecatcher
                    discount={discountValue}
                    discountDisplayType={
                        discountDisplayType
                    }
                    layer={layer}
                    multiple={multiple}
                />
            ) : null}
            <ShippingEyecatcher
                freeShipping={freeShipping}
            />
            <PromotionEyecatcher promotion={promotion} />
        </StyledEyecatchersContainer>
    )
}

Eyecatchers.propTypes = {
    product: PropTypes.object.isRequired,
    layer: PropTypes.bool,
}

Eyecatchers.defaultProps = {
    layer: false,
}
