// hier komponenten etc.
import {
    ThemeProvider as StyledThemeProvider,
    StyleSheetManager,
} from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import React from 'react'
import PropTypes from 'prop-types'
import { useRoutes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { SettingsContext } from './settings'
import { getRoutes } from './routes/index'
import { OffsetProvider } from './providers/OffsetProvider'

const shoutldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
        return isPropValid(propName)
    }
    return true
}

export const ThemeProvider = ({ children }) => {
    const theme = useSelector((state) => state.theme)
    return (
        <StyleSheetManager
            shouldForwardProp={shoutldForwardProp}
        >
            <StyledThemeProvider theme={theme}>
                {children}
            </StyledThemeProvider>
        </StyleSheetManager>
    )
}

ThemeProvider.propTypes = {
    children: PropTypes.any.isRequired,
}

/* istanbul ignore next */
// TODO: add test
const App = ({ isSSR, store }) => {
    const element = useRoutes(
        getRoutes(store.dispatch, isSSR),
    )
    return (
        <OffsetProvider>
            <SettingsContext.Provider value={{}}>
                <ThemeProvider>{element}</ThemeProvider>
            </SettingsContext.Provider>
        </OffsetProvider>
    )
}

App.propTypes = {
    store: PropTypes.any,
    theme: PropTypes.object.isRequired,
    isSSR: PropTypes.bool,
}

App.defaultProps = {
    store: {},
    isSSR: false,
}

export default App
