/* istanbul ignore file */
// keine jest tests, da über cypress getestet bzw. die einzelnen
// aufgerufenen funktionen in index.test.js abgedeckt sind

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

// actions
import { ProductActions } from '../store/actions/fetch'

// components
import { Layout } from '../components/Layout'
import { Footer } from '../components/Footer'
import { ProductsGrid } from '../components/ProductsGrid'
import { ProductLayerContainer as ProductLayer } from '../components/ProductLayer'
import { useTracking } from '../hooks/useTracking'

const SearchEntry = ({ isSSR }) => {
    const [TrackingTypes, track] = useTracking()
    useEffect(() => {
        !isSSR &&
            track({
                type: TrackingTypes.CLICK_IN,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <ProductsGrid />
}

SearchEntry.propTypes = {
    isSSR: PropTypes.bool,
}

SearchEntry.defaultProps = {
    isSSR: false,
}

const TagsEntry = ({ isSSR }) => {
    const [TrackingTypes, track] = useTracking()
    useEffect(() => {
        !isSSR &&
            track({
                type: TrackingTypes.CLICK_IN,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <ProductsGrid />
}

TagsEntry.propTypes = {
    isSSR: PropTypes.bool,
}

TagsEntry.defaultProps = {
    isSSR: false,
}

const ProductsEntry = ({ isSSR }) => {
    const [TrackingTypes, track] = useTracking()
    useEffect(() => {
        !isSSR &&
            track({
                type: TrackingTypes.CLICK_IN,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ProductsGrid isSSR={isSSR} />
            <ProductLayer isSSR={isSSR} />
            <Outlet />
        </>
    )
}

ProductsEntry.propTypes = {
    isSSR: PropTypes.bool,
}

ProductsEntry.defaultProps = {
    isSSR: false,
}

const routes = [
    {
        key: 'home',
        path: '/',
        static: true, // loadData wird nur serverseitig getriggert
        element: (
            <>
                <Layout />
                <Outlet />
                <Footer />
            </>
        ),
        loadData: [],
        children: [
            {
                key: 'search',
                path: 'search',
                element: <SearchEntry />,
                loadData: [
                    (params) =>
                        ProductActions.fetch(params),
                ],
            },
            {
                key: 'product',
                path: ':id',
                element: <ProductsEntry />,
                loadData: [
                    (params) =>
                        ProductActions.fetch(params),
                ],
            },
            {
                key: 'tags',
                path: 't/:tags',
                element: <TagsEntry />,
                loadData: [
                    (params) =>
                        ProductActions.fetch(params),
                ],
            },
        ],
    },
]

export default routes
