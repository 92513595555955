import flatten from 'lodash.flatten'
import { standardSizes } from './standard'
import { distinct } from '../index'

export const sort = (
    input,
    options = {
        useStandard: true,
    },
) => {
    if (!options.useStandard) {
        return input.sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase()),
        )
    }

    const compare = (xs, ys) => {
        const x = xs.numbers[0]
        const y = ys.numbers[0]

        // both numbers
        if (x - y === 0) {
            return compare(
                {
                    string: xs.string,
                    numbers: xs.numbers.slice(1),
                },
                {
                    string: ys.string,
                    numbers: ys.numbers.slice(1),
                },
            )
        }
        return x - y

        // compare wird nur für numerics aufgerufen? -> outcomment
        // // y number
        // if (isNaN(x) && !isNaN(y)) {
        //     return -1
        // }

        // // x number
        // if (!isNaN(x) && isNaN(y)) {
        //     return 1
        // }

        // // no number
        // return xs.string
        //     .toLowerCase()
        //     .localeCompare(ys.string.toLowerCase())
    }

    const getSortedNumerics = (sizes) => {
        return sizes
            .map((size) => {
                const nums = `${size}`
                    .replace(/[^0-9]/g, ' ')
                    .split(' ')
                    .filter((x) => x.length > 0)
                    .map(Number)
                    .filter((x) => !isNaN(x))
                return {
                    numbers: nums.length
                        ? nums
                        : [Number.MAX_VALUE],
                    string: size,
                }
            })
            .filter((o) => o.numbers.length > 0)
            .sort(compare)
            .map((n) => n.string)
    }

    // extend standard
    const matches = input
        .map((v) => {
            const m = standardSizes.filter((s) => {
                return new RegExp('\\b' + s + '\\b').test(v)
            })

            if (m.length > 0) {
                const r = {
                    standard: m.reduce((acc, val) => {
                        if (val.length > acc.length) {
                            acc = val
                        }
                        return acc
                    }, ''),
                    input: v,
                }

                return r
            }

            return null
        })
        // nur größen mit gefundener alternative behalten
        .filter(Boolean)

    const extendedStandard = flatten(
        standardSizes.map((s) => {
            // matches
            const extensions = matches
                .filter((m) => m.standard === s)
                .map((m) => m.input)
            return [s, ...getSortedNumerics(extensions)]
        }),
    )

    const nonStandard = input.filter((size) => {
        return extendedStandard.indexOf(size) === -1
    })

    // numerics of non standard
    const numerics = getSortedNumerics(nonStandard)

    // non standard + non numerics
    const rest = nonStandard
        .filter((size) => {
            return numerics.indexOf(size) === -1
        })
        // TODO check why sort is never called
        .sort(
            /* istanbul ignore next */ (a, b) => {
                return a
                    .toLowerCase()
                    .localeCompare(b.toLowerCase())
            },
        )

    return distinct(
        [...extendedStandard, ...numerics, ...rest].filter(
            (size) => input.indexOf(size) !== -1,
        ),
    )
}

const toSortedMap = (array) =>
    array.reduce((acc, val, index) => {
        acc[val] = index
        return acc
    }, {})

export default (input, options) =>
    toSortedMap(sort(input, options))
