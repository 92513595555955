// utils for js objects

export const getNumProperties = (obj) =>
    Object.keys(obj).length

export const mapEntries = (obj, fn) =>
    Object.keys(obj).map((key, idx) =>
        fn(key, obj[key], idx),
    )

export const omitKeys = (obj, keys) => {
    return Object.keys(obj)
        .filter((key) => !keys.includes(key))
        .reduce((acc, key) => {
            return {
                ...acc,
                [key]: obj[key],
            }
        }, {})
}
