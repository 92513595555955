export const headline = (state = '', action) => {
    switch (action.type) {
        case 'SERVER_INIT': {
            const categoryPath =
                action.payload.model.product?.categoryPath
            if (categoryPath) {
                const tokens = categoryPath.split('>')
                return tokens.pop().trim()
            }

            return state
        }
        default:
            return state
    }
}
