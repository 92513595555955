import React, {
    useState,
    useLayoutEffect,
    useCallback,
    useContext,
} from 'react'

import PropTypes from 'prop-types'

export function useElementSize() {
    const [ref, setRef] = useState(null)
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    })

    const handleSize = useCallback(() => {
        setSize({
            width: ref?.offsetWidth || 0,
            height: ref?.offsetHeight || 0,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.offsetHeight, ref?.offsetWidth])

    useLayoutEffect(() => {
        handleSize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.offsetHeight, ref?.offsetWidth])

    return [setRef, size]
}

const SizeContext = React.createContext({
    refs: [],
    sizes: [{}, {}],
})

export const useOffset = (index) => {
    const { refs, sizes } = useContext(SizeContext)

    return [refs[index], sizes[0].height + sizes[1].height]
}

export const OffsetProvider = ({ children }) => {
    const [ref0, size0] = useElementSize()
    const [ref1, size1] = useElementSize()

    return (
        <SizeContext.Provider
            value={{
                refs: [ref0, ref1],
                sizes: [size0, size1],
            }}
        >
            {children}
        </SizeContext.Provider>
    )
}

OffsetProvider.propTypes = {
    children: PropTypes.any,
}

OffsetProvider.defaultProps = {
    children: [],
}
