import React from 'react'
import styled from 'styled-components'

import { mapAdvancedBreakpoints } from '../../utils'

export const Input = styled.input`
    display: block;
    width: 100%;
    outline: none;

    ${(props) => {
        return mapAdvancedBreakpoints((name, _, index) => {
            const prop =
                props[name] ||
                (props.styles && props.styles[index])

            if (prop) {
                return prop
            }
        }, props.theme)
    }}
`
