import { useEffect } from 'react'
import { scrollLock } from '../utils'

export const useScrollLock = (enabled) => {
    useEffect(() => {
        if (enabled) {
            scrollLock.enable()
        } else {
            scrollLock.disable()
        }
    }, [enabled])
}
