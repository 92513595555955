import React from 'react'
import { useSelector } from 'react-redux'
import reactStringReplace from 'react-string-replace'
import merge from 'lodash.merge'

export const resolveTranslation = (
    message,
    variables,
    cb,
) => {
    let text = message?.pattern.slice()
    const v = merge({}, variables, message.variables)
    const vs = Object.keys(v).map((key) => ({
        name: key,
        value: v[key],
    }))

    text = reactStringReplace(
        text,
        /\{(.*?)\}/g,
        (match) => {
            const { name, value } =
                vs.find(
                    (variable) => variable.name === match,
                ) || {}

            if (name) {
                if (cb) {
                    return cb(name, value)
                }
                return <span key={name}>{value}</span>
            }

            return match
        },
    )

    return text
}

export const useTranslation = (path, variables, cb) => {
    const translation = useSelector((state) => {
        const p =
            typeof path === 'string'
                ? path.split('.')
                : path
        return p.reduce((acc, val) => {
            return acc && acc[val]
        }, state.translations)
    })

    if (!translation) {
        return ''
    }

    if (typeof translation === 'string') {
        return translation
    }

    if (
        typeof translation === 'object' &&
        !translation.pattern
    ) {
        return translation
    }

    return resolveTranslation(translation, variables, cb)
}
