/* istanbul ignore file */

import { useEffect, useState } from 'react'

import { useDebouncedCallback } from 'use-debounce'

const getWindow = () => {
    if (typeof window === 'undefined') {
        return null
    }

    return window
}

const getWindowSize = () => {
    return {
        width: getWindow()?.innerWidth,
        height: getWindow()?.innerHeight,
    }
}

export const useWindowResize = () => {
    const [dimensions, setDimensions] = useState(
        getWindowSize(),
    )

    const handler = useDebouncedCallback(() => {
        setDimensions(getWindowSize())
    }, 500)

    useEffect(() => {
        getWindow()?.addEventListener('resize', handler)

        return () => {
            getWindow()?.removeEventListener(
                'resize',
                handler,
            )
        }
    }, [handler])

    return dimensions
}
