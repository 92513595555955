import { useDeepQuery } from './useQuery'

export const useFeatureToggle = (
    id,
    defaultValue = false,
) => {
    const [query] = useDeepQuery()
    const activated =
        (query['cb.ft1'] && query['cb.ft1'].split('~')) ||
        []
    const deactivated =
        (query['cb.ft0'] && query['cb.ft0'].split('~')) ||
        []

    const active = activated.filter(
        (f) => !deactivated.includes(f),
    )

    if (active.includes(id)) {
        return true
    }

    if (deactivated.includes(id)) {
        return false
    }

    return defaultValue
}

export const FeatureToggleIds = {
    SECONDARY_BUY_BUTTON: 'sbb',
}
