import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import {
    Column,
    Container,
    Row,
} from '@conversionbuddy/Layout'

// children
import { Product } from './components/Product'
import { Controls } from './components/Controls'
import { Banner } from './components/Banner'
import { Headline } from './components/Headline'
import {
    Pagination,
    PreviousPagination,
} from './components/Pagination'
import { useExternalNavigate } from '../../hooks/useExternalNavigate'
import { useQuery } from '../../hooks/useQuery'
import { useOffset } from '../../providers/OffsetProvider'
import { useIsIOSSafariPrivate } from '../../hooks/useIsIOSSafariPrivate'
import { useOverlayState } from '../../hooks/useOverlayState'
import { Anchor } from '../Layout/Anchor'
import { omit, times } from '../../utils'
import { ProductPlaceholder } from './components/ProductPlaceholder'
import isEqual from 'lodash.isequal'

const StyledProductsGrid = styled(Container)``

const ProductColumn = styled(Column)`
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #efefef;
    }
    padding: 8px;
`

const HeadlineColumn = styled(Column)`
    display: flex;
    align-items: center;
`

const ProductsGridTop = () => {
    const containerStyles =
        useTheme().productsGrid?.container?.top?.styles ||
        []

    const [ref] = useOffset(1)
    return (
        <Container
            ref={ref}
            styles={containerStyles}
        >
            <Row>
                <Column>
                    <Banner />
                </Column>
            </Row>
            <Row>
                <HeadlineColumn>
                    <Headline />
                </HeadlineColumn>
            </Row>
            <Controls />
        </Container>
    )
}

const getCurrentPage = (products, offset, device) => {
    return Math.ceil(
        (products.length + offset) /
            (device !== 'DESKTOP' ? 40 : 80),
    )
}

export const fillProducts = (
    products,
    device,
    total,
    offset,
) => {
    const currentPage = getCurrentPage(
        products,
        offset,
        device,
    )

    return times(
        Math.min(
            currentPage * (device === 'DESKTOP' ? 80 : 40) -
                offset,
            total - offset,
        ),
        (i) => products[i],
    )
}

export const getUtils = (
    products,
    device,
    total,
    offset = 0,
) => {
    return {
        fillProducts: () =>
            fillProducts(
                products,
                device,
                total,
                Number(offset),
            ).reduce(
                (acc, product) => {
                    if (
                        !product &&
                        acc.nullishProducts <
                            (device === 'DESKTOP' ? 8 : 4)
                    ) {
                        return {
                            products: [
                                ...acc.products,
                                product,
                            ],
                            nullishProducts:
                                acc.nullishProducts + 1,
                        }
                    }

                    if (product) {
                        return {
                            ...acc,
                            products: [
                                ...acc.products,
                                product,
                            ],
                        }
                    }

                    return acc
                },
                {
                    products: [],
                    nullishProducts: 0,
                },
            ).products,
        getPage: () =>
            getCurrentPage(products, offset, device),
    }
}

export const usePreviousQueries = (
    query,
    omitKeys = [],
) => {
    const [previousQueries, setPreviousQueries] = useState(
        [],
    )
    useEffect(() => {
        const q = omit(query, omitKeys)

        if (
            !isEqual(
                previousQueries[previousQueries.length - 1],
                q,
            )
        ) {
            setPreviousQueries((prev) => [...prev, q])
        }
    }, [query, previousQueries, omitKeys])

    return previousQueries
}

export const ProductsGrid = React.memo(() => {
    const isIOSSafariPrivate = useIsIOSSafariPrivate()
    const theme = useTheme()
    const products = useSelector((state) => state.products)
    const total = useSelector((state) => state.meta.total)
    const device = useSelector((state) => state.device)
    const [navigate, TrackingTypes] = useExternalNavigate()
    const [query, setQuery, _, location] = useQuery(true)
    const previousQueries = usePreviousQueries(query, [
        'offset',
    ])
    const [hidden] = useOverlayState()

    // Beschreibt den "wirklichen" Offset, d.h. wieviele Produkte
    // vor dem ersten sichtbaren Produkt nicht angezeigt werden.
    // Nicht zu verwechseln mit dem offset aus der query, welcher
    // sich beim Scrollen ändert und das Nachladen von Produkten
    // anstößt
    const [offset, setOffset] = useState(
        Number(query.offset) || 0,
    )

    const ProductsGridUtils = getUtils(
        products,
        device,
        total,
        offset,
    )

    const handleAppearProduct = (index) => () => {
        const nums = {
            MOBILE: 10,
            TABLET: 20,
            DESKTOP: 20,
        }

        if (index === products.length - 1) {
            if (
                Number(query.offset || 0) <
                Math.min(
                    total,
                    ProductsGridUtils.getPage() *
                        (nums[device] * 4),
                ) -
                    nums[device]
            ) {
                return setQuery({
                    ...query,
                    offset:
                        Number(query.offset || 0) +
                        nums[device],
                })
            }
        }
    }

    const handleClickProduct = (
        product,
        sku,
        promotion,
    ) => {
        const variant = sku || product.skus[0]
        if (promotion && !isIOSSafariPrivate) {
            return setQuery(
                {
                    ...query,
                    'cbjs.voucherLayer': '1',
                    'cbjs.productLayer': '0',
                },
                TrackingTypes.CLICK_INTERNAL,
                // history state
                {
                    voucher: {
                        redirectUrl: variant.url,
                        sku: variant,
                        product,
                        trackingType:
                            TrackingTypes.CROSS_OUT_PRODUCT,
                        trackingPayload: {
                            targetVariant: sku
                                ? sku.sku
                                : null,
                            targetProduct: product.id,
                        },
                    },
                },
            )
        }

        navigate(variant.url, {
            type: TrackingTypes.CROSS_OUT_PRODUCT,
            targetProduct: product.id,
            targetVariant: sku ? sku.sku : null,
        })
    }

    const renderProducts = () =>
        ProductsGridUtils.fillProducts().map(
            (product, index) => {
                const key = product
                    ? `${product?.id}_${previousQueries.length}`
                    : index

                return (
                    <ProductColumn
                        key={key}
                        data-product-id={product?.id}
                        data-testid={`product.column_${product?.id}`}
                        styles={theme.productColWidth}
                    >
                        {product ? (
                            <Product
                                key={key}
                                index={index}
                                product={product}
                                onAppear={handleAppearProduct(
                                    index,
                                )}
                                onClick={handleClickProduct}
                            />
                        ) : (
                            <ProductPlaceholder />
                        )}
                    </ProductColumn>
                )
            },
        )

    return (
        <>
            <ProductsGridTop />
            <Anchor active={!hidden} />
            <PreviousPagination
                device={device}
                location={location}
                offset={offset}
                setOffset={setOffset}
            />
            <StyledProductsGrid id='list'>
                <Row>{renderProducts()}</Row>
            </StyledProductsGrid>
            <Pagination realOffset={offset} />
        </>
    )
})

ProductsGrid.propTypes = {
    isSSR: PropTypes.bool,
}

ProductsGrid.defaultProps = {
    isSSR: false,
}

ProductsGrid.displayName = 'ProductsGrid'
