import React from 'react'
import styled from 'styled-components'

export const Button = styled('button')`
    appearance: none;
    border: none;
    background-color: inherit;
    display: ${(props) =>
        props.block ? 'block' : 'inline-block'};
    width: ${(props) => (props.block ? '100%' : 'auto')};
    cursor: pointer;
`
