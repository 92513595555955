import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Box } from '@conversionbuddy/Layout'
import { TrackingTypes } from '../../../hooks/useTracking'
import { useFeatureToggle } from '../../../hooks/useFeatureToggle'
import { times } from '../../../utils'

const StyledProductLayerBanner = styled(Box)`
    background-size: contain;
    background-repeat: no-repeat;
`

export const createStyles = (banner, hidden = []) => {
    const visibleStyles = [
        `
            background-color: transparent !important;
            background-image: url(${
                banner.sources.xs.imageUrl
            });
            padding-top: calc(${Math.ceil(
                (banner.sources.xs.height /
                    banner.sources.xs.width) *
                    100,
            )}% + 10px);
        `,
        `
            background-color: transparent !important;
            background-image: url(${
                banner.sources.sm.imageUrl
            });
            padding-top: calc(${Math.ceil(
                (banner.sources.sm.height /
                    banner.sources.sm.width) *
                    100,
            )}% + 10px);
        `,
        `
            background-image: url(${
                banner.sources.lg.imageUrl
            });
            padding-top: calc(${Math.ceil(
                (banner.sources.lg.height /
                    banner.sources.lg.width) *
                    100,
            )}%);
            top: -${
                (banner.sources.lg.height /
                    banner.sources.lg.width) *
                    900 +
                10
            }px;
            height: auto;
            position: absolute; 
        `,
    ]
    return times(3, (i) =>
        hidden[i] ? '' : visibleStyles[i],
    )
}

export const Banner = ({ onClick }) => {
    const hideOnMobile = useFeatureToggle(
        'productLayer.hideMobileBanner',
        false,
    )

    const banner = useSelector((state) => {
        return state.banners?.find((b) =>
            b.slots.includes('productLayerTop'),
        )
    })

    if (!banner) {
        return null
    }

    const handleClick = () => {
        if (banner.targetUrl) {
            return onClick({
                targetUrl: banner.targetUrl,
                trackingTypeOut: TrackingTypes.CLICK_OUT_AD,
            })
        }

        return onClick()
    }

    return (
        <StyledProductLayerBanner
            role='banner'
            styles={createStyles(banner, [hideOnMobile])}
            onClick={handleClick}
        />
    )
}

Banner.propTypes = {
    onClick: PropTypes.func.isRequired,
}
