import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Tap } from './svgs/index'

import {
    Box,
    Button,
    getStyles,
} from '@conversionbuddy/Layout'
import {
    InternalParamsConstants,
    useInternalParamToggle,
} from '../../../../hooks/useInternalParams'

import { useDeepQuery } from '../../../../hooks/useQuery'
import { useFeatureToggle } from '../../../../hooks/useFeatureToggle'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import { useTranslation } from '../../../../hooks/useTranslation'

const ProductLayerDetailsButton = styled(Button)`
    ${(props) =>
        getStyles([
            'productLayer.buttons.default',
            props.primary && 'productLayer.buttons.primary',
            props.secondary &&
                'productLayer.buttons.secondary',
            props.buy && 'productLayer.buttons.buy',
        ])}
`

const ProductLayerDetailsButtonGroup = styled(Box)`
    ${getStyles(['productLayer.buttonGroup.container'])}
`

const ProductLayerDetailsPrimaryButtonGroup = styled.div`
    display: flex;
    width: 100%;
`

const ProductLayerDetailsBuyButton = ({ onClick }) => {
    const visible = useFeatureToggle(
        'productLayer.additionalBuyButton',
        true,
    )
    const buy = useInternalParamToggle(
        InternalParamsConstants.BUY,
    )

    return visible && buy ? (
        <ProductLayerDetailsButton
            buy
            onClick={onClick(false)}
        />
    ) : null
}

ProductLayerDetailsBuyButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

const ProductLayerDetailsPrimaryButton = ({
    onClick,
    shoppingCart,
}) => {
    const cartText = useTranslation('layer.buttons.cart')
    const buyText = useTranslation('layer.buttons.buy')
    const detailsText = useTranslation(
        'layer.buttons.details',
    )

    const buy = useInternalParamToggle(
        InternalParamsConstants.BUY,
    )

    const ft = useFeatureToggle(
        'productLayer.additionalBuyButton',
        true,
    )

    const displayAsBuy = buy && !ft

    const text = displayAsBuy
        ? shoppingCart
            ? cartText
            : buyText
        : detailsText

    return (
        <ProductLayerDetailsButton
            block
            primary
            onClick={onClick(!displayAsBuy)}
        >
            {text}
        </ProductLayerDetailsButton>
    )
}

ProductLayerDetailsPrimaryButton.propTypes = {
    shoppingCart: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}

ProductLayerDetailsPrimaryButton.defaultProps = {
    shoppingCart: false,
}

const effect1 = keyframes`
    0% {
            opacity: 1;
            -webkit-transform: scale3d(0.4, 0.4, 1);
            transform: scale3d(0.4, 0.4, 1);
        }
    100% {
        opacity: 0;
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transform: scale3d(1.2, 1.2, 1);
    }
`

const effect2 = keyframes`
    0% {
            opacity: 1;
            -webkit-transform: scale3d(0.4, 0.4, 1);
            transform: scale3d(0.4, 0.4, 1);
    }
    50%,
    100% {
        opacity: 0;
        -webkit-transform: scale3d(1.3, 1.3, 1);
        transform: scale3d(1.3, 1.3, 1);
    }
`

const ProductLayerDetailsSecondaryButtonAnimation = styled.div`
    position: relative;
    padding-left: 13px;
    top: 25px;
    &:before,
    &:after {
        content: '';

        display: inline-block;
        position: absolute;
        left: 0;
        top: -14px;
        height: 50px;
        width: 50px;
        z-index: 1;

        border: 3px solid #fff;
        border-radius: 50%;
    }

    &:before {
        animation: ${effect1} 1.5s forwards;
        animation-iteration-count: infinite;
    }
    &:after {
        animation: ${effect2} 1.5s forwards;
        animation-iteration-count: infinite;
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
`

const ProductLayerDetailsSecondaryButton = ({
    ...props
}) => {
    const translation = useTranslation(
        'layer.buttons.moreProducts',
    )

    return (
        <ProductLayerDetailsButton
            block
            secondary
            {...props}
        >
            {translation}
            <ProductLayerDetailsSecondaryButtonAnimation>
                <Tap />
            </ProductLayerDetailsSecondaryButtonAnimation>
        </ProductLayerDetailsButton>
    )
}

export const Buttons = ({ onClickOut, shoppingCart }) => {
    const [query, setQuery, TrackingTypes] = useDeepQuery()
    const isHandVisible = useFeatureToggle(
        'productLayer.hand',
        true,
    )
    const isMoreProductsButtonVisible = useFeatureToggle(
        'productLayer.more',
        true,
    )
    const isDesktop = useMediaQuery('(min-width: 900px)')

    const isSecondaryButtonVisible = isDesktop
        ? isMoreProductsButtonVisible
        : isHandVisible

    const handleClose = () => {
        setQuery(
            {
                ...query,
                'cbjs.productLayer': '0',
            },
            TrackingTypes.CLICK_INTERNAL,
        )
    }

    const handleClickOut = (ignoreShoppingCart) => (e) =>
        onClickOut(
            e,
            TrackingTypes.CLICK_OUT_BUTTON,
            ignoreShoppingCart,
        )

    return (
        <ProductLayerDetailsButtonGroup>
            <ProductLayerDetailsPrimaryButtonGroup>
                <ProductLayerDetailsPrimaryButton
                    shoppingCart={shoppingCart}
                    onClick={handleClickOut}
                />
                <ProductLayerDetailsBuyButton
                    shoppingCart={shoppingCart}
                    onClick={handleClickOut}
                />
            </ProductLayerDetailsPrimaryButtonGroup>
            {isSecondaryButtonVisible ? (
                <ProductLayerDetailsSecondaryButton
                    onClick={handleClose}
                />
            ) : null}
        </ProductLayerDetailsButtonGroup>
    )
}

Buttons.propTypes = {
    shoppingCart: PropTypes.bool,
    onClickOut: PropTypes.func.isRequired,
}

Buttons.defaultProps = {
    shoppingCart: false,
}
