import { useFetch } from '../../hooks/useFetch'
import React from 'react'

const Data = ({ children, isSSR, loadData }) => {
    useFetch(loadData)

    return React.cloneElement(children, {
        ...children.props,
        isSSR,
    })
}

export default React.memo(Data, () => true)
