import {
    Dropdown,
    DropdownOption,
} from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { useSorting } from '../../hooks'

export const Sorting = ({ mobile, onClick, open }) => {
    const theme = useTheme()
    const sortings = useSelector((state) => state.sorting)
    const [setSorting, getSelectedOption] =
        useSorting(sortings)
    const getLabelOption = (option) => option.label

    const sortingPrefix = useTranslation('sorting.prefix')
    const handleClick = () => {
        onClick && onClick()
    }
    const selectedOption = getSelectedOption().label

    const renderOptions = (options) =>
        options.map((option) => {
            const handleOptionClick = () => {
                handleClick()
                setSorting(option.order, option.value)
            }

            const isOptionSelected =
                getSelectedOption().label === option.label

            return (
                <DropdownOption
                    key={option.label}
                    selected={isOptionSelected}
                    onClick={handleOptionClick}
                >
                    {getLabelOption(option)}
                </DropdownOption>
            )
        })

    const dropdownProps = {
        key: 'sorting',
        label: sortingPrefix,
        open: open,
    }

    return mobile ? (
        <Dropdown
            mobile={mobile}
            {...dropdownProps}
            nested
            borderColor={theme.variables.colors.border}
            selected={selectedOption}
            onClick={handleClick}
        >
            {renderOptions(sortings)}
        </Dropdown>
    ) : (
        <Dropdown
            {...dropdownProps}
            borderColor={theme.variables.colors.border}
            selected={selectedOption}
            onClick={handleClick}
        >
            {renderOptions(sortings)}
        </Dropdown>
    )
}

Sorting.propTypes = {
    onClick: PropTypes.func,
    open: PropTypes.bool,
    mobile: PropTypes.bool,
}

Sorting.defaultProps = {
    open: false,
    mobile: false,
    onClick: () => {},
}
