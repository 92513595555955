import styled from 'styled-components'
import {
    mapAdvancedBreakpoints,
    getGutter,
    getStyles,
} from '../../utils'

const percentage = (n) =>
    n ? `${(n * 100).toFixed(8)}%;` : ''

const width = (span, columns) => {
    if (!span || !columns) {
        return ''
    }

    return `width: ${percentage(span / columns)};`
}

const css = (name, value) => {
    if (name && value !== undefined) {
        return `${name}: ${value};`
    }
}

export const Column = styled.div`
    ${(props) => getGutter(props.theme, 'padding')}

    ${(props) => {
        const columns = props.theme?.grid?.columns
        return mapAdvancedBreakpoints((name, _, index) => {
            const prop =
                props[name] ||
                (props.styles && props.styles[index])
            const isNumberProp = !isNaN(prop)

            const span = isNumberProp ? prop : prop?.span
            const offset =
                (prop &&
                    !isNumberProp &&
                    (prop.offset || 0)) ||
                0
            const order =
                prop && !isNumberProp && prop.order

            if (prop) {
                return `
          flex: 0 0 auto;
          ${width(span, columns)}
          ${css(
              'margin-right',
              percentage(offset / columns),
          )}
        `
            }

            if (name === 'xs') {
                return `
          flex-shrink: 0;
          width: 100%;
          max-width: 100%;
          flex: 1 0;
        `
            }
        }, props.theme)
    }}
  
  ${getStyles()}
`
