import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useDeepQuery } from './useQuery'
import { useDeepParams } from './useDeepParams'

export const useDidUpdateEffect = (fn, inputs) => {
    const didMountRef = useRef(false)

    useEffect(() => {
        if (didMountRef.current) {
            return fn()
        }
        didMountRef.current = true
        // TODO:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs)
}

export const useFetch = (loadFn) => {
    const params = useDeepParams()
    const location = useLocation()

    const [query] = useDeepQuery(false, [
        'cbjs.productLayer',
        'cbjs.voucherLayer',
    ])

    useDidUpdateEffect(() => {
        loadFn({
            ...location,
            query,
        })
    }, [params, query])
}
