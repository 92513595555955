import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Header } from '../Header'
import { Voucher } from '../Voucher'

import { Reset, getStyles } from '@conversionbuddy/Layout'
import { useOffset } from '../../providers/OffsetProvider'
import { Preview } from './Preview'

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
    * {
        ${getStyles(['global'])}
    }

    html, body {
        margin: 0;
        padding: 0;
        color: ${(props) =>
            props.theme.variables.colors.text};
    }

    html {
        font-size: 15px;
        
    }
`

export const Layout = () => {
    const [ref] = useOffset(0)
    return (
        <div
            ref={ref}
            className='header'
        >
            <GlobalStyle />
            <Reset />
            <Header />
            <Voucher />
            <Preview />
        </div>
    )
}
