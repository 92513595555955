import styled from 'styled-components'
import { getStyles } from '../../utils'

export const HorizontalList = styled.ul`
    ${getStyles(['layout.horizontalList.ul'])}
`

export const HorizontalListItem = styled.li`
    ${getStyles(['layout.horizontalList.li'])}
`
