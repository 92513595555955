import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PreviewActions } from '../../../store/actions/preview'

const validateMessage = (e) => {
    if (!e.data.markup || !e.data.assets || !e.data.theme) {
        return false
    }

    return true
}

export const Preview = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const handler = (e) => {
            if (validateMessage(e)) {
                dispatch(PreviewActions.update(e.data))
            }
        }
        window.addEventListener('message', handler)

        return () =>
            window.removeEventListener('message', handler)
    }, [dispatch])

    return null
}
