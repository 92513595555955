import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getStyles } from '@conversionbuddy/Layout'
import { useExternalNavigate } from '../../../hooks/useExternalNavigate'

const StyledLink = styled.a`
    ${getStyles()}
`

export const CustomStaticLink = ({
    children,
    code,
    splitBy,
    trackingType,
    ...props
}) => {
    const getSplitBy = () => {
        if (splitBy === 'SPACE') {
            return ' '
        }

        return splitBy
    }
    const link = useSelector((state) =>
        state.links.custom.find((l) => l.code === code),
    )

    const [navigate, TrackingTypes] = useExternalNavigate()

    const handleClick = (e) => {
        e.preventDefault()
        const isValidTrackingType = Object.keys(
            TrackingTypes,
        ).find((key) => TrackingTypes[key] === trackingType)
        navigate(link.url, {
            type: isValidTrackingType ? trackingType : null,
        })
    }

    const renderInner = () => {
        if (
            !children ||
            !getSplitBy() ||
            React.Children.count(children) !==
                link.name.split(getSplitBy()).length
        ) {
            return link.name
        }

        return React.Children.map(
            children,
            (child, index) => {
                const childText = link.name.split(
                    getSplitBy(),
                )[index]
                    ? `${
                          link.name.split(getSplitBy())[
                              index
                          ]
                      }${
                          index !== children.length - 1
                              ? getSplitBy()
                              : ''
                      }`
                    : null
                return React.cloneElement(child, {
                    ...child.props,
                    children: childText,
                })
            },
        )
    }

    return link ? (
        <StyledLink
            href={link.url}
            onClick={handleClick}
            {...props}
        >
            {renderInner()}
        </StyledLink>
    ) : null
}

CustomStaticLink.propTypes = {
    children: PropTypes.any,
    code: PropTypes.string.isRequired,
    trackingType: PropTypes.string.isRequired,
    splitBy: PropTypes.string,
}

CustomStaticLink.defaultProps = {
    splitBy: null,
    children: null,
}
