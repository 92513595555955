import { useLocation, useNavigate } from 'react-router-dom'
import { useTracking } from './useTracking'

const createHref = (to) => {
    if (to.search && typeof to.search === 'string') {
        return `${to.pathname}${to.search}`
    }

    return to.pathname || to
}

const createNextLocation = (to, before) => {
    if (!to) {
        return {
            ...before,
        }
    }
    if (typeof to === 'string') {
        return {
            ...before,
            pathname: to,
        }
    }

    return {
        ...before,
        ...to,
    }
}

export const useInternalNavigate = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [TrackingTypes, track] = useTracking()

    const enhancedNavigate = (
        to,
        options,
        trackingType,
    ) => {
        // send tracking event if trackingType is given
        if (trackingType) {
            track({
                type: trackingType,
                target: to
                    ? createHref(to)
                    : createHref(location),
            })
        }

        const nextState = {
            state: {
                ...location.state,
                ...options.state,
            },
        }

        const params = [
            createNextLocation(to, location),
            {
                ...nextState,
                replace: options?.replace || false,
            },
        ]

        navigate(...params)
    }

    return [enhancedNavigate, TrackingTypes]
}
