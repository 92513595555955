import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Attributes } from '../../../../../Layout/Attributes'
import { useDispatch } from 'react-redux'
import { getStyles } from '@conversionbuddy/Layout'
import { useDimensions } from '../../../../../../hooks/useDimensions'
import { selectSku } from '../../../../../../store/actions/activeSku'

const StyledAttributesContainer = styled.div`
    bottom: ${(props) => props.offset}px;
    display: ${(props) =>
        props.visible ? 'block' : 'none'};
    ${getStyles([
        'productsGrid.product.details.attributes.container',
    ])}
`

export const ProductAttributes = ({
    boxRef,
    onClick,
    product,
    visible,
}) => {
    const dispatch = useDispatch()
    const handleSelect = (sku) => {
        onClick(sku)
    }

    const handleDeselect = () => {
        dispatch(selectSku(null))
    }

    const [_, dimensions] = useDimensions(true, boxRef)

    return (
        <StyledAttributesContainer
            data-testid={`pg/attributes/${product.id}`}
            offset={dimensions.height}
            visible={visible}
            onClick={(e) => {
                e.stopPropagation()
                // preventDefault to stop link behaviour
                e.preventDefault()
            }}
        >
            <Attributes
                grid
                maxButtons={6}
                product={product}
                onDeselect={handleDeselect}
                onSelect={handleSelect}
            />
        </StyledAttributesContainer>
    )
}

ProductAttributes.propTypes = {
    boxRef: PropTypes.any.isRequired,
    offset: PropTypes.number,
    visible: PropTypes.bool,
    product: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
}

ProductAttributes.defaultProps = {
    offset: 0,
    visible: false,
}
