import { ProductUtils } from '../../utils'

export const products = (state = [], action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            return action.payload.model.products
                .map(ProductUtils.removeShortDescription)
                .map(ProductUtils.removeNullish)

        case 'FETCH_SUCCESS': {
            const currentProducts = state
            const nextProducts =
                action.payload.model.products
                    .map(
                        ProductUtils.removeShortDescription,
                    )
                    .map(ProductUtils.removeNullish)

            const offset = Number(
                action.payload.location?.query?.offset || 0,
            )
            if (offset >= currentProducts.length) {
                return [...currentProducts, ...nextProducts]
            }

            if (offset === 0) {
                return nextProducts
            }

            return currentProducts
        }

        case 'FETCH_PREV_SUCCESS': {
            const currentProducts = state
            const prevProducts =
                action.payload.model.products
                    .map(
                        ProductUtils.removeShortDescription,
                    )
                    .map(ProductUtils.removeNullish)

            return [...prevProducts, ...currentProducts]
        }

        default:
            return state
    }
}
