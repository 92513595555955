import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { Filter } from '../Filter'
import { PriceRange } from '../PriceRange'

const selectFilters = (state) => state.filters
const selectDisplayNames = (_, displayNames) => displayNames
const select = createSelector(
    [selectFilters, selectDisplayNames],
    (filters, displayNames) =>
        filters
            .filter(
                (filter) =>
                    filter.type === 'multiValue' ||
                    filter.type === 'range',
            )
            .map((filter) => ({
                ...filter,
                displayName: displayNames[filter.name],
            })),
)

export const Filters = ({
    activeFilter,
    getSelectedOptions,
    mobile,
    onApplyRange,
    onFilterClick,
    onOptionClick,
}) => {
    const displayNames = useTranslation('filters')

    const filters = useSelector((state) =>
        select(state, displayNames),
    )

    const handleFilterClick = (filter) => {
        onFilterClick && onFilterClick(filter)
    }

    const renderFilters = () =>
        filters.map((filter) => {
            if (filter.type === 'range') {
                return (
                    <PriceRange
                        key={filter.name}
                        filter={filter}
                        isSelected={
                            getSelectedOptions(filter)
                                ?.length !== 0
                        }
                        mobile={mobile}
                        open={
                            activeFilter?.name ===
                            filter.name
                        }
                        onApplyRange={onApplyRange}
                        onClick={handleFilterClick}
                    />
                )
            }

            return (
                <Filter
                    key={filter.name}
                    filter={filter}
                    isMobile={mobile}
                    isSearchable={filter.searchable}
                    open={
                        activeFilter?.name === filter.name
                    }
                    selected={getSelectedOptions(filter)}
                    onClick={handleFilterClick}
                    onOptionClick={onOptionClick}
                />
            )
        })

    return renderFilters()
}

Filters.propTypes = {
    mobile: PropTypes.bool,
    onFilterClick: PropTypes.func.isRequired,
    activeFilter: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    getSelectedOptions: PropTypes.func.isRequired,
    onApplyRange: PropTypes.func,
    onOptionClick: PropTypes.func.isRequired,
}

Filters.defaultProps = {
    mobile: false,
    activeFilter: null,
    onApplyRange: () => {},
}
