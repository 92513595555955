import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getStyles } from '@conversionbuddy/Layout'

// utils
const useTitle = (product) => {
    const titleType = useSelector(
        (state) => state.general?.productList?.titleStyle,
    )

    if (titleType === 'titleOnly') {
        if (product.title.startsWith(product.brand)) {
            return [
                product.title
                    .replace(product.brand, '')
                    .trim(),
            ]
        }

        return [product.title]
    }

    if (titleType === 'dualLineBrandTitle') {
        if (product.title.startsWith(product.brand)) {
            return [
                product.title
                    .replace(product.brand, '')
                    .trim(),
                product.brand,
            ]
        }

        return [product.title]
    }

    if (titleType === 'dualLineBrandTitleWithBrand') {
        return [product.title, product.brand]
    }

    return [product.title]
}

const StyledProductTitle = styled.h4`
    ${getStyles(['productsGrid.product.details.title'])}
`

const StyledProductBrand = styled.div`
    ${getStyles(['productsGrid.product.details.brand'])}
`

export const ProductTitle = ({ product }) => {
    const [title, brand] = useTitle(product)

    if (brand) {
        return (
            <>
                <StyledProductBrand>
                    {brand}
                </StyledProductBrand>
                <StyledProductTitle>
                    {title}
                </StyledProductTitle>
            </>
        )
    }

    return <StyledProductTitle>{title}</StyledProductTitle>
}

ProductTitle.propTypes = {
    product: PropTypes.object.isRequired,
}
