import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Logo } from '../../Layout/Logo'
import {
    Box,
    Icon,
    getStyles,
} from '@conversionbuddy/Layout'

const ProductLayerHeaderContainer = styled(Box)`
    right: 0;
    height: 3rem;
    width: 100%;
    border-bottom: 1px solid transparent;
    background-color: white;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    @media screen and (min-width: 900px) {
        position: absolute;
        width: 50%;
        border-bottom: 0;
        height: 3.5rem;
    }
`

const ProductLayerHeader = styled(Box)`
    height: 3rem;
    display: flex;
    border-top-right-radius: 4px;

    @media screen and (min-width: 900px) {
        height: 3.5rem;
    }
`

const ProductLayerHeaderLogo = styled(Box)`
    ${getStyles(['productLayer.header.logo'])}
`

const ProductLayerHeaderClose = styled(Box)`
    height: 3rem;
    width: 3rem;
    cursor: pointer;

    @media screen and (min-width: 900px) {
        border-top-right-radius: 4px;
        height: 3.5rem;
        width: 3.5rem;
    }
`

const ProductLayerHeaderCloseIconDesktop = styled(Box)`
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 900px) {
        display: flex;
        transform: translate(-9px, 9px);
    }
`

const ProductLayerHeaderCloseIconMobile = styled(Box)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid transparent;

    @media screen and (min-width: 900px) {
        display: none;
    }
`

const types = {
    onClickClose: PropTypes.func.isRequired,
    onClickOut: PropTypes.func.isRequired,
}

export const Header = ({ onClickClose, onClickOut }) => {
    const handleClickOut = () => {
        onClickOut()
    }

    return (
        <ProductLayerHeaderContainer>
            <ProductLayerHeader>
                <ProductLayerHeaderLogo>
                    <Logo
                        layer
                        align='left'
                        ratio={4.0}
                        onClick={handleClickOut}
                    />
                </ProductLayerHeaderLogo>
                <ProductLayerHeaderClose
                    data-testid='headerClose'
                    onClick={onClickClose}
                >
                    <ProductLayerHeaderCloseIconDesktop>
                        <Icon
                            color='#000000'
                            dimensions={[14, 14]}
                            direction='top'
                            strokeWidth={2}
                            variant='Cross'
                        />
                    </ProductLayerHeaderCloseIconDesktop>
                    <ProductLayerHeaderCloseIconMobile>
                        <Icon
                            color='#000000'
                            dimensions={[14, 14]}
                            direction='top'
                            strokeWidth={1}
                            variant='Cross'
                        />
                    </ProductLayerHeaderCloseIconMobile>
                </ProductLayerHeaderClose>
            </ProductLayerHeader>
        </ProductLayerHeaderContainer>
    )
}

Header.propTypes = types
