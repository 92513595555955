/* istanbul ignore file */

import { useState, useEffect } from 'react'
import { useIntersectionObserver } from 'react-intersection-observer-hook'
import { useGlobalSettings } from './useGlobalSettings'

export const useAppeared = (cb) => {
    const settings = useGlobalSettings()
    const [appeared, setAppeared] = useState(
        settings.appeared || false,
    )
    const [ref, { entry }] = useIntersectionObserver()
    const isVisible = entry && entry.isIntersecting

    useEffect(() => {
        if (isVisible && !appeared) {
            setAppeared(true)
            cb()
        }
    }, [isVisible, cb, appeared])

    return [ref, appeared]
}
