import {
    Box,
    Button,
    Container,
    Icon,
} from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { Badges } from '../Badges'
import { Filters } from '../Filters'
import { Gender } from '../Gender'
import { Sorting } from '../Sorting'
import { useFilters } from '../../hooks'

const ResetFiltersButton = styled(Button)`
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:hover {
        background-color: #f5f5f5;
    }
    padding: 4px;
`
const StyledResetIcon = styled.div`
    margin-right: 5px;
`

const BadgesWrapper = styled(Box)`
    display: flex;
`

const DesktopControlsContainer = ({ children }) => {
    const styles = [
        `
        display: none;
    `,
        `
        display: flex;
        gap:10px;
        margin-bottom: 1rem;
        flex-wrap:wrap;
    `,
    ]
    return (
        <Container
            fluid
            styles={styles}
        >
            {children}
        </Container>
    )
}

DesktopControlsContainer.propTypes = {
    children: PropTypes.array,
}

DesktopControlsContainer.defaultProps = {
    children: [],
}

export const DesktopControls = ({
    activeFilter,
    onFiltersClick,
    onSortingClick,
    setActiveFilter,
}) => {
    const [
        setFilter,
        getSelectedOptions,
        clearFilters,
        selectedOptionsByFilter,
    ] = useFilters()
    const { reset } = useTranslation('mobileFilter')
    /* istanbul ignore next */
    const handleFiltersClickOutside = (filter) => {
        if (filter.name === activeFilter?.name) {
            setActiveFilter(null)
        }
    }

    const handleOptionClick = (filter, option) => {
        setFilter(filter, option.value)
    }

    const hasAppliedFilters =
        Object.keys(selectedOptionsByFilter).length > 0

    return (
        <DesktopControlsContainer>
            <Gender />
            <Sorting
                open={activeFilter === 'sort'}
                onClick={onSortingClick}
            />
            <Filters
                activeFilter={activeFilter}
                getSelectedOptions={getSelectedOptions}
                onClickOutside={handleFiltersClickOutside}
                onFilterClick={onFiltersClick}
                onOptionClick={handleOptionClick}
            />
            <BadgesWrapper>
                <Badges />
                {hasAppliedFilters && (
                    <ResetFiltersButton
                        onClick={clearFilters}
                    >
                        <StyledResetIcon>
                            <Icon
                                color='#000000'
                                dimensions={[16, 16]}
                                variant='Reset'
                            />
                        </StyledResetIcon>
                        {reset}
                    </ResetFiltersButton>
                )}
            </BadgesWrapper>
        </DesktopControlsContainer>
    )
}

DesktopControls.propTypes = {
    activeFilter: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    setActiveFilter: PropTypes.func.isRequired,
    onFiltersClick: PropTypes.func.isRequired,
    onSortingClick: PropTypes.func.isRequired,
}

DesktopControls.defaultProps = {
    activeFilter: null,
}
