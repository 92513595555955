import { useState } from 'react'

export const useHover = () => {
    const [hovered, setHovered] = useState(false)

    return [
        hovered,
        {
            onMouseOver: () => setHovered(true),
            onMouseLeave: () => setHovered(false),
        },
    ]
}
