const getCurrencyCode = (symbol) => {
    if (symbol === '€') {
        return 'EUR'
    }

    return symbol
}

export const config = (state = {}, action) => {
    const {
        decimal,
        decimalPoint,
        multiplePrefix,
        symbol,
        symbolPosition,
        thousandSep,
    } =
        action?.payload?.model?.generalConfig?.currency ||
        {}
    const language =
        action?.payload?.model?.generalConfig?.language
    switch (action.type) {
        case 'SERVER_INIT':
            return {
                language,
                currency: {
                    code: getCurrencyCode(symbol),
                    symbol,
                    display: 'symbol',
                    position:
                        symbolPosition === 'prefix'
                            ? 'before'
                            : 'after',
                    separator: {
                        decimal: decimalPoint,
                        thousand: thousandSep,
                    },
                    decimal,
                    multiplePrefix,
                },
            }
        default:
            return state
    }
}
