import React from 'react'
import styled from 'styled-components'

import { extractStyleProps, getStyles } from '../../utils'

/**
 * Box-Komponente nimmt gesamten Bereich des Parent-Elementes an
 */

export const Box = styled.div`
    width: 100%;
    height: 100%;

    ${getStyles()}
`

Box.Flex = styled(Box)`
    display: flex;
`

export const Center = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const GenericBox = styled.div((props) => ({
    ...extractStyleProps(props),
}))

Box.Aspect = ({ ratio, additionalHeight, children }) => {
    const outerStyles = [
        `
            position: relative;
        `,
    ]

    const innerStyles = [
        `
            padding-top: calc(${100 / ratio}% + ${
            additionalHeight || 0
        }px);
        `,
    ]

    return (
        <Box styles={outerStyles}>
            <Box styles={innerStyles}>{children}</Box>
        </Box>
    )
}
