import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useExternalNavigate } from '../../../hooks/useExternalNavigate'
import { Row, getStyles } from '@conversionbuddy/Layout'
import { useTranslation } from '../../../hooks/useTranslation'

const PriceInfoRow = styled(Row)`
    ${getStyles(['footer.priceInfo.row'])}
`

const StyledLink = styled.a`
    ${getStyles(['footer.priceInfo.link'])}
`

const Link = ({ children, href }) => {
    const [navigate, TrackingTypes] = useExternalNavigate()

    const handleClick = (e) => {
        e.preventDefault()
        navigate(href, {
            type: TrackingTypes.CROSS_OUT_INFO,
        })
    }
    return (
        <StyledLink
            href={href}
            onClick={handleClick}
        >
            {children}
        </StyledLink>
    )
}

Link.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
}

export const PriceInfo = () => {
    const links = useSelector((state) => state.links)
    const phrase = useTranslation(
        'priceInfos.footer',
        null,
        (name, value) => {
            const link = links[name]?.url
            return link ? (
                <Link
                    key={name}
                    href={link}
                >
                    {value}
                </Link>
            ) : (
                <span key={name}>{value}</span>
            )
        },
    )

    return <PriceInfoRow>{phrase}</PriceInfoRow>
}
