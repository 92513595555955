import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useSelector } from 'react-redux'

const shine = keyframes`
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
`

const StyledPlaceholder = styled.div`
    background: linear-gradient(
        to right,
        rgba(130, 130, 130, 0.2) 8%,
        rgba(130, 130, 130, 0.3) 18%,
        rgba(130, 130, 130, 0.2) 33%
    );
    background-size: 800px 100px;
    animation: wave-squares 2s infinite ease-out;
    animation: ${shine} 1.5s infinite ease-out;
`

export const ProductPlaceholder = () => {
    const imageDimensions = useSelector(
        (state) => state.general.dimensions,
    )

    const ratio =
        imageDimensions.height / imageDimensions.width

    return (
        <div>
            <StyledPlaceholder
                style={{
                    paddingTop: `${Math.floor(
                        ratio * 100,
                    )}%`,
                }}
            />
            <StyledPlaceholder
                style={{
                    height: '30px',
                    marginTop: '10px',
                }}
            />
            <StyledPlaceholder
                style={{
                    height: '20px',
                    marginTop: '10px',
                }}
            />
        </div>
    )
}
