import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, getStyles } from '@conversionbuddy/Layout'
import { usePrices } from '../../../../hooks/usePrices'
import { useTranslation } from '../../../../hooks/useTranslation'
import { useSelector } from 'react-redux'
import { SaleEyecatcher } from '../../../Layout/Eyecatchers'

const ProductLayerDetailsPrices = styled(Box)`
    ${(props) =>
        getStyles([
            'productLayer.prices',
            props.discountDisplayType === 'prices'
                ? 'productLayer.pricesWithEyecatcher'
                : '',
        ])(props)}
`

const ProductLayerDetailsPrice = styled.div`
    display: ${(props) =>
        !props.children ? 'none' : 'block'};
    ${(props) =>
        getStyles([
            ['productLayer', 'price'],
            props.old && ['productLayer', 'oldPrice'],
            props.sale && ['productLayer', 'salePrice'],
        ])(props)}
`

const ProductLayerDetailsBasePrice = styled.div`
    ${(props) =>
        getStyles([
            'productLayer.basePrice',
            props.sale && 'productLayer.salePrice',
        ])(props)}
`

const ProductLayerDetailsLowestPrice = styled.div`
    ${getStyles(['productLayer.lowestPrice'])}
`
const ProductLayerPriceInfosContainer = styled.div`
    ${getStyles([
        'productLayer.priceInformation.container',
    ])}
`
const ProductLayerPriceInfoText = styled.div`
    ${getStyles(['productLayer.priceInformation'])}
`

export const Prices = ({ onClick }) => {
    const lowestPriceTranslation = useTranslation(
        'prices.lowestPrice',
    )

    const {
        basePrice,
        basePriceUnit,
        current: price,
        discount,
        lowestPrice,
        multiple,
        multiplePrefix,
        old: oldPrice,
        priceInfo,
        pricePrefix,
        sale,
    } = usePrices()
    const discountDisplayType = useSelector(
        (state) =>
            state.general.eyecatchers.discountDisplayType,
    )
    const discountValue =
        discount && (discount * 100).toFixed(0)
    return (
        <>
            <ProductLayerDetailsPrices
                discountDisplayType={discountDisplayType}
                onClick={onClick}
            >
                {oldPrice && (
                    <ProductLayerDetailsPrice old>
                        {pricePrefix
                            ? `${pricePrefix} `
                            : multiple
                            ? `${multiplePrefix} `
                            : ''}
                        {oldPrice}
                    </ProductLayerDetailsPrice>
                )}
                <ProductLayerDetailsPrice sale={sale}>
                    {pricePrefix
                        ? `${pricePrefix} `
                        : multiple
                        ? `${multiplePrefix} `
                        : ''}
                    {price}
                </ProductLayerDetailsPrice>

                {discountDisplayType === 'prices' ? (
                    <SaleEyecatcher
                        layer
                        discount={discountValue}
                        discountDisplayType={
                            discountDisplayType
                        }
                        multiple={multiple}
                    />
                ) : null}
            </ProductLayerDetailsPrices>
            {basePrice ? (
                <ProductLayerDetailsBasePrice sale={sale}>
                    {basePrice} / {basePriceUnit}
                </ProductLayerDetailsBasePrice>
            ) : null}
            <ProductLayerPriceInfosContainer>
                {priceInfo ? (
                    <ProductLayerPriceInfoText>
                        {priceInfo}
                    </ProductLayerPriceInfoText>
                ) : null}
                {lowestPrice ? (
                    <ProductLayerDetailsLowestPrice>
                        {lowestPriceTranslation}:{' '}
                        {lowestPrice}
                    </ProductLayerDetailsLowestPrice>
                ) : null}
            </ProductLayerPriceInfosContainer>
        </>
    )
}

Prices.propTypes = {
    onClick: PropTypes.func.isRequired,
}
