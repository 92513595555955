import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import {
    Container,
    Column,
    Row,
    Box,
    Span,
    getStyles,
} from '@conversionbuddy/Layout'

import { Logo } from '../Layout/Logo'
import { Menu } from '../Layout/Menu'
import { Navigation } from '../Navigation'
import { Search } from '../Search'
import { CustomStaticLink } from '../Layout/StaticLink'
import { renderer } from '../../utils/react'
import { Scheduler } from '../Layout/Scheduler'

const HeaderContainer = styled.div`
    ${getStyles(['header.container'])}
`

export const componentMap = {
    Container: Container,
    Box: Box,
    Fragment: React.Fragment,
    Navigation: Navigation,
    Search: Search,
    Logo: Logo,
    Column: Column,
    Row: Row,
    Menu: Menu,
    CustomStaticLink: CustomStaticLink,
    Span: Span,
    Scheduler: Scheduler,
}

export const Header = () => {
    const template = useSelector(
        (state) => state.template.header,
    )

    const el = renderer(componentMap, template)

    return <HeaderContainer>{el}</HeaderContainer>
}
