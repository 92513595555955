import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { getStyles } from '@conversionbuddy/Layout'
import { useSelector } from 'react-redux'
import { useFeatureToggle } from '../../../../../../hooks/useFeatureToggle'
import { useGenderFilter } from '../../hooks'
import { useTranslation } from '../../../../../../hooks/useTranslation'

const GenderFilterContainer = styled.div`
    ${getStyles(['productsGrid.controls.gender.container'])}
`

export const StyledGenderFilterButtonContainer = styled.div`
    ${(props) =>
        getStyles([
            'productsGrid.controls.gender.button.default',
            props.selected
                ? 'productsGrid.controls.gender.button.selected'
                : '',
            props.disabled
                ? 'productsGrid.controls.gender.button.disabled'
                : '',
        ])(props)}

    // TODO extract to theme
    &:first-child {
        margin-right: 0.25rem;
    }

    &:last-child {
        margin-left: 0.25rem;
    }
`

const StyledGenderFilterButtonIcon = styled.div`
    ${getStyles([
        'productsGrid.controls.gender.icon.wrapper',
    ])}
`

const StyledGenderFilterButtonText = styled.div`
    ${getStyles(['productsGrid.controls.gender.text'])}
`

const GenderFilterButton = ({
    children,
    disabled,
    onClick,
    selected,
}) => {
    return (
        <StyledGenderFilterButtonContainer
            disabled={disabled}
            selected={selected}
            onClick={onClick}
        >
            <StyledGenderFilterButtonIcon />
            <StyledGenderFilterButtonText>
                {children}
            </StyledGenderFilterButtonText>
        </StyledGenderFilterButtonContainer>
    )
}

GenderFilterButton.propTypes = {
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
}

GenderFilterButton.defaultProps = {
    selected: false,
    disabled: false,
}

const GenderFilter = () => {
    const labels = useTranslation('gender')
    const [setFilter, getSelection] = useGenderFilter()
    const enabled = useSelector(
        (state) =>
            state.filters.find(
                (f) => f.param === 'filters.gender',
            )?.options,
    )

    const handleClick = (option) => () => {
        setFilter(option)
    }

    return (
        <GenderFilterContainer>
            <GenderFilterButton
                disabled={!enabled.includes('female')}
                selected={getSelection().includes('female')}
                onClick={handleClick('female')}
            >
                {labels.female}
            </GenderFilterButton>
            <GenderFilterButton
                disabled={!enabled.includes('male')}
                selected={getSelection().includes('male')}
                onClick={handleClick('male')}
            >
                {labels.male}
            </GenderFilterButton>
        </GenderFilterContainer>
    )
}

export const Gender = () => {
    const active = useFeatureToggle('gender')
    return active ? <GenderFilter /> : null
}
