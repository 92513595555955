import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useSelector } from 'react-redux'
import { getStyles } from '@conversionbuddy/Layout'
import { useTranslation } from '../../../../hooks/useTranslation'
import { useQuery } from '../../../../hooks/useQuery'

const StyledHeadline = styled.h2`
    ${getStyles(['productsGrid.headline'])}
`
const ProductsBadge = styled.div`
    text-align: center;
    width: auto;
    background-color: #f2f2f2;
    color: #000000;
    border-radius: 15px;
    padding: 0.2rem 0.85rem;
    margin-left: 0.8rem;
    font-size: 0.9rem;
`

export const Headline = () => {
    const q = useQuery()
    const location = q[q.length - 1]
    const headline = useSelector((state) => state.headline)
    const productCount = useSelector(
        (state) => state.meta.total,
    )

    if (location.pathname?.startsWith?.('/search')) {
        return <SearchHeadline searchTerm={q[0]['q']} />
    }

    return headline ? (
        <>
            <StyledHeadline>{headline}</StyledHeadline>
            <ProductsBadge>{productCount}</ProductsBadge>
        </>
    ) : null
}

const SearchHeadline = ({ searchTerm }) => {
    const products = useSelector((state) => state.products)

    const headline = useTranslation(
        'productsGrid.headline.search',
        {
            searchTerm,
        },
    )

    const emptySearchHeadline = useTranslation(
        'productsGrid.headline.emptySearch',
        {
            searchTerm,
        },
    )

    if (products.length === 0) {
        return emptySearchHeadline && searchTerm ? (
            <StyledHeadline>
                {emptySearchHeadline.map((token) => (
                    <span
                        key={token}
                        style={{
                            whiteSpace: 'pre',
                        }}
                    >
                        {token}
                    </span>
                ))}
            </StyledHeadline>
        ) : null
    }

    return headline && searchTerm ? (
        <StyledHeadline>
            {headline.map((token) => (
                <span
                    key={token}
                    style={{
                        whiteSpace: 'pre',
                    }}
                >
                    {token}
                </span>
            ))}
        </StyledHeadline>
    ) : null
}

SearchHeadline.propTypes = {
    searchTerm: PropTypes.string,
}

SearchHeadline.defaultProps = {
    searchTerm: '',
}
