import { useState } from 'react'
import { identity } from '../utils'

export const useInput = (
    initialValue,
    transform = identity,
) => {
    const [value, setValue] = useState(initialValue)

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    return [transform(value), handleChange]
}
