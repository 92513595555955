import styled from 'styled-components'
import { mapAdvancedBreakpoints } from '../../utils'

export const Text = styled.span`
    /* font-size: ${(props) => props.fontSize || 'inherit'};
    margin-top: ${(props) => props.marginTop || 0};
    margin-bottom: ${(props) => props.marginBottom || 0}; */

    ${(props) => {
        return mapAdvancedBreakpoints((name, _, index) => {
            const prop =
                props[name] ||
                (props.styles && props.styles[index])

            if (prop) {
                return prop
            }
        }, props.theme)
    }}
`
