import React from 'react'

// components
import Data from '../components/Data'

// config
import routes from './config'

export const createLoadData =
    (route, dispatch) => (location) =>
        route.loadData.forEach((loadFn) =>
            dispatch(loadFn(location)),
        )

export const createDataElement = (
    route,
    dispatch,
    isSSR,
) => {
    return route.static
        ? {
              element: route.element,
          }
        : {
              element: (
                  <Data
                      isSSR={isSSR}
                      loadData={createLoadData(
                          route,
                          dispatch,
                      )}
                  >
                      {route.element}
                  </Data>
              ),
          }
}

export const createLoaders = (route, dispatch) => {
    return route.loadData
        ? {
              loadData: async (location) => {
                  const promises = route.loadData.map(
                      (loadFn) =>
                          dispatch(loadFn(location)),
                  )
                  return Promise.all(promises)
              },
          }
        : {}
}

// export const memoize = (func) => {
//     // a cache of results
//     const results = {}
//     // return a function for the cache of results
//     return (...args) => {
//         // a JSON key to save the results cache
//         const argsKey = JSON.stringify(args)
//         // execute `func` only if there is no cached value of clumsysquare()
//         if (!results[argsKey]) {
//             // store the return value of clumsysquare()
//             results[argsKey] = func(...args)
//         }
//         // return the cached results
//         return results[argsKey]
//     }
// }

export const getRoutes = (dispatch, isSSR) => {
    return generateRoutes(routes, dispatch, isSSR)
}

export const generateRoutes = (config, dispatch, isSSR) => {
    return config.map((route) => {
        return {
            ...route,
            ...createLoaders(route, dispatch),
            ...createDataElement(route, dispatch, isSSR),
            ...(route.children
                ? {
                      children: generateRoutes(
                          route.children,
                          dispatch,
                          isSSR,
                      ),
                  }
                : {}),
        }
    })
}
