import slotConfig from './slots'
import { omit } from '../../../utils'

export const applySources = (bannerSources, bannerSlot) => {
    // fill banner sources by mobile first strategy
    const slotConfigArray = Object.keys(
        slotConfig[bannerSlot],
    )
        .map((breakpoint) => ({
            breakpoint,
            width: slotConfig[bannerSlot][breakpoint].width,
            height: slotConfig[bannerSlot][breakpoint]
                .height,
        }))
        .sort((a, b) => a.width - b.width)

    const mergedSources = slotConfigArray.reduce(
        (acc, slot) => {
            // check if banner has config for current breakpoint
            if (bannerSources[slot.breakpoint]) {
                acc.aux = {
                    ...bannerSources[slot.breakpoint],
                    inherit: slot.breakpoint,
                }
            }

            acc.aux &&
                acc.merged.push(
                    omit(
                        {
                            ...acc.aux,
                            breakpoint: slot.breakpoint,
                            slotSize: {
                                width: slot.width,
                                height: slot.height,
                            },
                            imageSize: acc.aux.width &&
                                acc.aux.height && {
                                    width: acc.aux.width,
                                    height: acc.aux.height,
                                },
                        },
                        ['width', 'height'],
                    ),
                )
            return acc
        },
        {
            merged: [],
            aux: null,
        },
    ).merged

    return mergedSources.reverse()
}

export const formatBanners = (banners) => {
    if (!banners) {
        return {}
    }

    const bannerBySlots = {}

    banners.forEach((banner) => {
        banner.slots.forEach((slot) => {
            bannerBySlots[slot] = [
                ...(bannerBySlots[slot] || []),
                {
                    ...omit(
                        {
                            ...banner,
                            sources: applySources(
                                banner.sources,
                                slot,
                            ),
                        },
                        ['slots'],
                    ),
                },
            ]
        })
    })

    return bannerBySlots
}

// reducer
export const bannerBySlots = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            return formatBanners(
                action.payload.model.bannerAds,
            )
        default:
            return state
    }
}

export const banners = (state = [], action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            return action.payload.model.bannerAds || state
        default:
            return state
    }
}
