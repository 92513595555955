import { hydrateRoot } from 'react-dom/client'
import React from 'react'

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

/* eslint-disable */

import App from '../common/index.js'
import { configureApi } from '../common/api/index.js'
import getStore from '../common/store/index.js'
import { init } from '../common/utils/sentry/index.js'

configureApi({
    baseUrl:
        ENVIRONMENT === 'LOCAL'
            ? window.__CB_ENDPOINT__
            : '',
})

const store = getStore(window.__PRELOADED_STATE__)

const AppContainer = () => {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <App store={store} />
            </Provider>
        </BrowserRouter>
    )
}

// init sentry
init()

hydrateRoot(
    document.getElementById('root'),
    <AppContainer />,
)
