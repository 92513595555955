import {
    clearNullishValues,
    isEmptyObject,
} from '../../../utils'

export const extractFromMarkup =
    (markup) =>
    (key, pred = () => true) => {
        const children = markup.children.find(
            (child) =>
                child.component === key &&
                pred(child.props),
        )?.children
        return (
            children && {
                component: 'Fragment',
                ...{
                    children,
                },
            }
        )
    }

export const extractPlaceholders = (markup) => {
    const placeholders = markup.children?.find(
        (child) => child.component === 'Placeholders',
    )
    if (placeholders?.children) {
        return placeholders.children.reduce(
            (acc, placeholder) => {
                if (placeholder?.props?.id) {
                    acc[placeholder.props.id] = {
                        component: 'Fragment',
                        children: placeholder.children,
                    }
                }

                return acc
            },
            {},
        )
    }
}

export const template = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT': {
            const extract = extractFromMarkup(
                action.payload.model.template.markup,
            )

            const positionedFooterTemplates =
                clearNullishValues({
                    start: extract(
                        'Footer',
                        (props) =>
                            props?.position === 'start',
                    ),
                    between: extract(
                        'Footer',
                        (props) =>
                            props?.position === 'between',
                    ),
                    end: extract(
                        'Footer',
                        (props) =>
                            props?.position === 'end',
                    ),
                })

            return {
                header: extract('Header'),
                footer: positionedFooterTemplates,
                placeholders: extractPlaceholders(
                    action.payload.model.template.markup,
                ),
            }
        }

        case 'UPDATE_TEMPLATE': {
            const extract = extractFromMarkup(
                action.payload.model.template.markup,
            )

            const positionedFooterTemplates =
                clearNullishValues({
                    start: extract(
                        'Footer',
                        (props) =>
                            props?.position === 'start',
                    ),
                    between: extract(
                        'Footer',
                        (props) =>
                            props?.position === 'between',
                    ),
                    end: extract(
                        'Footer',
                        (props) =>
                            props?.position === 'end',
                    ),
                })

            return {
                header: extract('Header'),
                footer: positionedFooterTemplates,
                placeholders: extractPlaceholders(
                    action.payload.model.template.markup,
                ),
            }
        }

        default:
            return state
    }
}
