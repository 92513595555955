import { sort } from '../../../utils/sort'

export const sortOptions = (options) => {
    const counts = options.reduce((acc, option) => {
        return {
            ...acc,
            [option.value]: option.count,
        }
    }, {})
    return sort(options.map((option) => option.value)).map(
        (optionValue) => ({
            value: optionValue,
            count: counts[optionValue],
        }),
    )
}

export const filters = (state = [], action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            return action.payload.model.filters
                .map((filter) => {
                    return {
                        ...filter,
                        options: sortOptions(
                            filter.options,
                        ),
                        searchable: filter.name === 'brand',
                    }
                })
                .concat(
                    action.payload.model.priceRange
                        ? {
                              name: 'price',
                              param: 'filters.price',
                              type: 'range',
                              ...action.payload.model
                                  .priceRange,
                          }
                        : [],
                )
        case 'FETCH_SUCCESS':
            return action.payload.model.filters
                .map((filter) => {
                    return {
                        ...filter,
                        options: sortOptions(
                            filter.options,
                        ),
                        searchable: filter.name === 'brand',
                    }
                })
                .concat(
                    action.payload.model.priceRange
                        ? {
                              name: 'price',
                              param: 'filters.price',
                              type: 'range',
                              ...action.payload.model
                                  .priceRange,
                          }
                        : [],
                )
        default:
            return state
    }
}
