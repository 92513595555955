export const activeSku = (state = null, action) => {
    switch (action.type) {
        case 'SERVER_INIT': {
            const product = action.payload.model.product
            if (!product) {
                return null
            }

            if (product.skus.length === 1) {
                return product.skus[0]
            }

            const variantId = action.payload.model.variantId

            return (
                product.skus.find(
                    (sku) => sku.sku === variantId,
                ) || null
            )
        }
        case 'SELECT_SKU':
            return action.payload.sku
        default:
            return state
    }
}
