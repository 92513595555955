import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, getStyles } from '@conversionbuddy/Layout'
import { cutTextByDots } from '../../../../utils'

export const calculateDescriptionAltStyles = ({
    dims,
    position,
}) => {
    if (!position || !dims) {
        return {
            display: 'none',
        }
    }

    return {
        left: `${position.left}px`,
        top: `${position.top}px`,
        width: `${dims.width}px`,
        maxHeight: `${dims.height}px`,
        whiteSpace: 'pre-line',
    }
}

const ProductLayerDetailsDescription = styled(Box)`
    ${getStyles(['productLayer.details.description'])}
`

const ProductLayerDetailsDescriptionRow = styled.span`
    ${getStyles(['productLayer.details.description.row'])}
`

const ProductLayerDetailsDescriptionAltContent = styled.div`
    ${getStyles(['productLayer.details.description.alt'])}
`

const ProductLayerDetailsDescriptionAlt = ({ text }) => {
    const [state, setState] = useState({})
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (typeof window !== 'undefined') {
                setState({
                    position: {
                        left: e.clientX + 10,
                        top: e.clientY + 10,
                    },
                    dims: {
                        width: Math.min(
                            window.innerWidth -
                                e.clientX -
                                30,
                            500,
                        ),
                        height:
                            window.innerHeight -
                            e.clientY -
                            30,
                    },
                })
            }
        }
        if (typeof window !== 'undefined') {
            window.addEventListener(
                'mousemove',
                handleMouseMove,
            )
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener(
                    'mousemove',
                    handleMouseMove,
                )
            }
        }
    }, [])

    const style = calculateDescriptionAltStyles(state)

    return (
        <ProductLayerDetailsDescriptionAltContent
            style={style}
        >
            {text}
        </ProductLayerDetailsDescriptionAltContent>
    )
}

ProductLayerDetailsDescriptionAlt.propTypes = {
    text: PropTypes.string.isRequired,
}

export const Description = ({
    maxHoverTextLength,
    maxRows,
    maxTextLength,
    minHoverTextLength,
    onClick,
    text,
}) => {
    const usps = useSelector(
        (state) => state.general.uniqueSellingPoints,
    )

    const [isAltTextVisible, setIsAltTextVisible] =
        useState(false)

    const description = usps ? `${text}<br>${usps}` : text
    const getSplitBreak = () => {
        if (description.indexOf('<br>') !== -1) {
            return '<br>'
        }

        if (description.indexOf('<BR>') !== -1) {
            return '<BR>'
        }

        if (description.indexOf('<bR>') !== -1) {
            return '<bR>'
        }

        if (description.indexOf('<Br>') !== -1) {
            return '<Br>'
        }

        return null
    }

    const hasRows = () =>
        getSplitBreak() &&
        description.split(getSplitBreak()).length > 1

    const splitText = () =>
        description.split(getSplitBreak()).slice(0, maxRows)

    return text ? (
        <ProductLayerDetailsDescription
            data-testid='description'
            onClick={onClick}
            onMouseEnter={() => {
                if (description.length >= maxTextLength) {
                    setIsAltTextVisible(true)
                }
            }}
            onMouseLeave={() => {
                setIsAltTextVisible(false)
            }}
        >
            {isAltTextVisible && (
                <ProductLayerDetailsDescriptionAlt
                    text={cutTextByDots(
                        splitText().join('\n'),
                        maxHoverTextLength,
                        minHoverTextLength,
                    )}
                />
            )}
            {splitText().map((row) => (
                <ProductLayerDetailsDescriptionRow
                    key={row}
                    style={{
                        whiteSpace: hasRows()
                            ? 'nowrap'
                            : 'normal',
                    }}
                >
                    {hasRows()
                        ? cutTextByDots(row, maxTextLength)
                        : cutTextByDots(row, maxTextLength)}
                </ProductLayerDetailsDescriptionRow>
            ))}
        </ProductLayerDetailsDescription>
    ) : null
}

Description.propTypes = {
    maxRows: PropTypes.number,
    maxHoverTextLength: PropTypes.number,
    minHoverTextLength: PropTypes.number,
    maxTextLength: PropTypes.number,
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

Description.defaultProps = {
    maxRows: 2,
    maxTextLength: 80,
    maxHoverTextLength: 600,
    minHoverTextLength: 500,
    text: '',
}
