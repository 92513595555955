import {
    Dropdown,
    DropdownOption,
    Icon,
} from '@conversionbuddy/Layout'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { useInput } from '../../../../../../hooks/useInput'
import { useTranslation } from '../../../../../../hooks/useTranslation'

const StyledFilterOptionTag = styled.div`
    text-align: center;
    width: auto;
    background-color: ${(props) =>
        props.isSelected ? 'black' : '#f2f2f2'};
    color: ${(props) =>
        props.isSelected ? '#ffffff' : 'black'};
    border-radius: 15px;
    padding: 0.2rem 0.85rem;
    margin-left: 0.8rem;
    font-size: 0.9rem;
    display: ${(props) => (props.hide ? 'none' : 'block')};
`
const StyledFilterOptionLabel = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledSearchContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    margin: 1rem 0;
    width: 100%;
`

const StyledSearchForm = styled.div`
    display: flex;
    position: relative;
    flex: 1;
    margin: ${(props) =>
        props.isMobile ? '0 24px 0 1rem' : '0 1rem'};
`

const StyledSearchIcon = styled.div`
    position: absolute;
    background-color: white;
    padding-left: 6px;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
`

const StyledSearchInput = styled.input`
    height: 3rem;
    border: 1px solid #aeaeae;
    cursor: pointer;
    width: 100%;
    padding: 12px;
    font-size: ${(props) =>
        props.isMobile ? '16px !important' : '15px'};
    &:focus {
        outline: transparent;
        border: 1px solid black;
    }
`
const StyledNoResultsText = styled.div`
    padding: 8px;
    font-style: italic;
    text-align: center;
`

const FilterOptionTag = ({ children, isSelected }) => {
    const isVisible = useSelector(
        (state) => !state.meta.isFilledProductsList,
    )

    return isVisible ? (
        <StyledFilterOptionTag isSelected={isSelected}>
            {children}
        </StyledFilterOptionTag>
    ) : null
}

FilterOptionTag.propTypes = {
    isSelected: PropTypes.bool,
    children: PropTypes.any.isRequired,
}

FilterOptionTag.defaultProps = {
    isSelected: false,
}

export const filterOptionsBySearchTerm = (
    options,
    searchTerm = '',
) =>
    options.filter((opt) => {
        const option = opt.value.toLowerCase()
        if (searchTerm.length <= 2) {
            return option.startsWith(searchTerm)
        } else {
            return option.includes(searchTerm)
        }
    })

export const SearchableFilterOptions = ({
    filter,
    isMobile,
    onClick,
    selectedOptions,
}) => {
    const transform = (str) => str.toLowerCase()
    const [search, onChange] = useInput('', transform)

    const { emptySearch, placeholder } =
        useTranslation('filterSearch')

    const options = useMemo(
        () =>
            filterOptionsBySearchTerm(
                filter.options,
                search,
            ),
        [filter, search],
    )

    return (
        <>
            <StyledSearchContainer>
                <StyledSearchForm isMobile={isMobile}>
                    <StyledSearchInput
                        isMobile={isMobile}
                        placeholder={placeholder}
                        type='text'
                        value={search}
                        onChange={onChange}
                    />
                    <StyledSearchIcon>
                        <Icon
                            color='#000000'
                            dimensions={[20, 20]}
                            variant='Search'
                        />
                    </StyledSearchIcon>
                </StyledSearchForm>
            </StyledSearchContainer>
            {options.length ? (
                <FilterOptions
                    filter={{
                        ...filter,
                        options,
                    }}
                    isMobile={isMobile}
                    selectedOptions={selectedOptions}
                    onClick={onClick}
                />
            ) : (
                <StyledNoResultsText>
                    {emptySearch}
                </StyledNoResultsText>
            )}
        </>
    )
}

SearchableFilterOptions.propTypes = {
    filter: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array,
}

SearchableFilterOptions.defaultProps = {
    isMobile: false,
    selectedOptions: [],
}

export const FilterOptions = ({
    filter,
    isMobile,
    onClick,
    selectedOptions,
}) => {
    return filter.options.map((option) => {
        const handleClick = () => {
            onClick(filter, option)
        }

        return (
            <DropdownOption
                key={option.value}
                data-testid={`dropdownOption_${
                    isMobile ? 'mobile' : 'desktop'
                }_${option.value}`}
                role='option'
                selected={selectedOptions.includes(
                    option.value,
                )}
                onClick={handleClick}
            >
                <StyledFilterOptionLabel>
                    {option.value}
                </StyledFilterOptionLabel>
                <FilterOptionTag
                    isSelected={selectedOptions.includes(
                        option.value,
                    )}
                >
                    {option.count}
                </FilterOptionTag>
            </DropdownOption>
        )
    })
}

export const Filter = ({
    filter,
    isMobile,
    isSearchable,
    onClick,
    onOptionClick,
    open,
    selected,
}) => {
    const theme = useTheme()

    const handleClick = () => {
        onClick(filter)
    }

    const getLabel = () => {
        if (selected.length) {
            return `${filter.displayName} (${selected.length})`
        }

        return filter.displayName
    }

    const OptionsElement =
        isSearchable && filter.options.length > 10
            ? SearchableFilterOptions
            : FilterOptions

    return (
        <Dropdown
            key={filter.name}
            borderColor={theme.variables.colors.border}
            label={getLabel()}
            mobile={isMobile}
            nested={isMobile}
            open={open}
            selected={selected}
            onClick={handleClick}
        >
            <OptionsElement
                filter={filter}
                isMobile={isMobile}
                isSearchable={isSearchable}
                selectedOptions={selected}
                onClick={onOptionClick}
            />
        </Dropdown>
    )
}

Filter.propTypes = {
    filter: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    isSearchable: PropTypes.bool,
    open: PropTypes.bool,
    isMobile: PropTypes.bool,
    selected: PropTypes.array,
}

Filter.defaultProps = {
    open: false,
    isMobile: false,
    selected: [],
    isSearchable: false,
}
