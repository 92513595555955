const Slots = {
    MOBILE: {
        width: 320,
        height: 50,
    },
    LARGE_MOBILE: {
        width: 320,
        height: 100,
    },
    LEADERBOARD: {
        width: 728,
        height: 90,
    },
    LARGE_LEADERBOARD: {
        width: 970,
        height: 90,
    },
}

const slotConfig = {
    productListTop: {
        '*': Slots.MOBILE,
        xs: Slots.MOBILE,
        sm: Slots.MOBILE,
        md: Slots.LEADERBOARD,
        lg: Slots.LEADERBOARD,
        xl: Slots.LARGE_LEADERBOARD,
    },
    productLayerTop: {
        '*': Slots.MOBILE,
        xs: Slots.MOBILE,
        sm: Slots.MOBILE,
        md: Slots.LEADERBOARD,
        lg: Slots.LEADERBOARD,
        xl: Slots.LARGE_LEADERBOARD,
    },
}

export default slotConfig
