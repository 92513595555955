import { useMemo, useState } from 'react'
import { useQuery } from './useQuery'

export const useOverlayState = (
    isIOSSafariPrivate = false,
) => {
    const [query, setQuery, _, location] = useQuery()
    const [hiddenByState, setHiddenByState] =
        useState(false)

    const isHidden = useMemo(
        () =>
            query?.['cbjs.productLayer'] === '0' ||
            hiddenByState ||
            location.pathname.startsWith('/search'),
        [query, hiddenByState, location],
    )

    const hide = () => {
        if (isIOSSafariPrivate) {
            setHiddenByState(true)
        }

        setQuery({
            ...query,
            'cbjs.productLayer': '0',
        })
    }

    return [isHidden, hide]
}
