import { useDeepQuery } from './useQuery'

export const InternalParamsConstants = {
    BUY: 'cb.buy',
    SESSION_ID: 'cb.sid',
}

// export const useInternalParams = (params) => {

// }

export const useInternalParam = (param, pred) => {
    const [query] = useDeepQuery()

    return pred(query[param])
}

export const useInternalParamToggle = (param) => {
    return useInternalParam(param, (value) =>
        [1, '1', 'true', true].includes(value),
    )
}
