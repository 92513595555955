import styled from 'styled-components'
import { getGutter, getStyles } from '../../utils'

const getFlexWrap = (props) => {
    if (props.reverse && props.$wrap) {
        return 'wrap-reverse'
    }

    if (props.$wrap) {
        return 'wrap'
    }

    return 'nowrap'
}

export const Row = styled.div`
    ${(props) => getGutter(props.theme, 'margin', true)}

    display: flex;
    flex-wrap: ${(props) => getFlexWrap(props)};
    ${getStyles()}
`

Row.defaultProps = {
    $wrap: true,
}
