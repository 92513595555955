import { useQuery } from './useQuery'
import { clearNullishValues } from '../utils'

export const usePagination = () => {
    const [query, setQuery, TrackingTypes] = useQuery()

    const getOffset = () => {
        return Number(query.offset || 0)
    }

    const setOffset = (offset) => {
        setQuery(
            clearNullishValues({
                ...query,
                offset,
            }),
            TrackingTypes.CLICK_INTERNAL,
        )
    }

    return [setOffset, getOffset]
}
