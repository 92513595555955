import { useState, useMemo } from 'react'
import { sort } from '../../../../utils/sort'
import { omit } from '../../../../utils'

// not used?
// export const getVariantsByAttribute = (
//     variants,
//     attributeKey,
//     attributeValue,
// ) => {
//     return variants.filter(
//         (variant) =>
//             variant[attributeKey] === attributeValue,
//     )
// }

// export const getVariantsByAttributes = (
//     variants,
//     attributes,
// ) => {
//     return attributes.reduce((vrts, attrs) => {
//         return getVariantsByAttribute(vrts, ...attrs)
//     }, variants)
// }

export const uniq = (arr) => {
    return arr.reduce((acc, val) => {
        if (acc.indexOf(val) === -1) {
            return [...acc, val]
        }
        return acc
    }, [])
}

const notNullish = (v) => v !== null && v !== undefined

export const validate = (product) => {
    if (!product.uniqueCharacteristics) {
        return false
    }
    return product.skus.every((sku) =>
        product.uniqueCharacteristics.every((uc) =>
            notNullish(sku[uc]),
        ),
    )
}

export const createInitial = (product, sku = {}) => {
    if (!validate(product)) {
        return {
            selection: {},
            options: [],
            length: 0,
        }
    }

    return product.uniqueCharacteristics.reduce(
        (acc, val) => {
            acc.options.push({
                uniqueCharacteristic: val,
                options: uniq(
                    product.skus.map((s) => s[val]),
                ),
            })
            acc.selection[val] = sku?.[val] ?? null
            acc.length += uniq(
                product.skus.map((s) => s[val]),
            ).length
            return acc
        },
        {
            selection: {},
            options: [],
            length: 0,
        },
    )
}

export const updateSelection = (
    uniqueCharacteristics,
    skus,
    prev,
    next,
) => {
    if (
        Object.keys(prev).length ===
        Object.keys(next).length
    ) {
        return next
    }

    // check ucs
    // take next uc
    const nextUniqueCharacteristic = uniqueCharacteristics
        .filter((uc) => {
            return !Object.keys(next).includes(uc)
        })
        .pop()

    const nextConfig = {
        ...next,
        [nextUniqueCharacteristic]:
            prev[nextUniqueCharacteristic],
    }

    // exists sku by uc config?
    const match = skus.find((sku) => {
        return Object.keys(nextConfig).every((uc) => {
            return (
                sku[uc] == nextConfig[uc] || !nextConfig[uc]
            )
        })
    })

    if (match) {
        return updateSelection(
            uniqueCharacteristics,
            skus,
            prev,
            nextConfig,
        )
    }

    return updateSelection(
        uniqueCharacteristics,
        skus,
        prev,
        {
            ...next,
            [nextUniqueCharacteristic]: null,
        },
    )
}

const getFilteredOptions = (
    skus,
    selection,
    currentUniqChar,
) => {
    // we dont want that the current UC limits the options
    // only limit options by other ucs
    const selectionArr = Object.entries(
        omit(selection, [currentUniqChar]),
    )

    const filteredOptions = skus.reduce((acc, sku) => {
        const isMatch = selectionArr.every(
            ([uniqueCharacteristic, value]) =>
                value === null ||
                sku[uniqueCharacteristic] === value,
        )

        if (isMatch) {
            const value = sku[currentUniqChar]
            if (!acc.includes(value)) {
                acc.push(value)
            }
        }

        return acc
    }, [])

    return filteredOptions
}

export const getCombinableOptions = (
    skus,
    options,
    selection,
) => {
    if (options.length < 2) {
        return options
    }

    const combinableOptions = options.map((option) => ({
        uniqueCharacteristic: option.uniqueCharacteristic,
        options: getFilteredOptions(
            skus,
            selection,
            option.uniqueCharacteristic,
        ),
    }))

    return combinableOptions
}

export const useAttributes = (
    product,
    sku,
    combine = true,
) => {
    const uniqueCharacteristics = combine
        ? ['combined']
        : product.uniqueCharacteristics
    const skus = combine
        ? product.skus.map((sku) => {
              return {
                  ...sku,
                  combined:
                      product.uniqueCharacteristics.reduce(
                          (acc, uc, idx) => {
                              return acc.concat(
                                  idx === 0
                                      ? sku[uc]
                                      : ` | ${sku[uc]}`,
                              )
                          },
                          '',
                      ),
              }
          })
        : product.skus

    const initial = createInitial(
        {
            ...product,
            uniqueCharacteristics,
            skus,
        },
        sku && skus.find((s) => s.sku === sku.sku),
    )

    const [selection, setSelection] = useState(
        initial.selection,
    )

    const options = initial.options

    const select = (key, value) => {
        const next = updateSelection(
            uniqueCharacteristics,
            skus,
            selection,
            {
                [key]: value === '-1' ? null : value,
            },
        )
        setSelection(next)
        return {
            selection: next,
            sku: skus.find((sku) => {
                return uniqueCharacteristics.every(
                    (uc) => sku[uc] == next[uc],
                )
            }),
        }
    }

    const combinableOptionsList = useMemo(() => {
        return getCombinableOptions(
            skus,
            options,
            selection,
        )
    }, [skus, options, selection])

    const optionsList = options.map((option) => {
        const combinableOption = combinableOptionsList.find(
            (co) =>
                co.uniqueCharacteristic ===
                option.uniqueCharacteristic,
        )

        return {
            ...option,
            options: sort(option.options).map((opt) => ({
                value: opt,
                disabled:
                    !combinableOption?.options.includes(
                        opt,
                    ),
            })),
        }
    })
    return [selection, select, optionsList, initial.length]
}
