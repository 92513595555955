import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const useInteraction = () => {
    const [interacted, setInteracted] = useState(false)

    useEffect(() => {
        const handleTouch = () => {
            if (!interacted) {
                setInteracted(true)
            }
        }

        window.addEventListener('touchstart', handleTouch)

        return () =>
            window.removeEventListener(
                'touchstart',
                handleTouch,
            )
    }, [interacted])

    return interacted
}

export const Anchor = ({ active }) => {
    const requestId = useRef()
    const anchorRef = useRef()

    const interacted = useInteraction()

    useEffect(() => {
        const animate = () => {
            if (anchorRef.current && !interacted) {
                window.scrollTo(
                    0,
                    anchorRef.current.offsetTop,
                )
            }

            if (!interacted && active) {
                requestId.current =
                    requestAnimationFrame(animate)
            } else {
                cancelAnimationFrame(requestId.current)
            }
        }

        if (active) {
            requestId.current =
                requestAnimationFrame(animate)
        }

        return () => cancelAnimationFrame(requestId.current)
    }, [interacted, active])
    return <div ref={anchorRef} />
}

Anchor.propTypes = {
    active: PropTypes.bool,
}

Anchor.defaultProps = {
    active: true,
}
