export const PreviewActions = {
    update: (template) => ({
        type: 'UPDATE_TEMPLATE',
        payload: {
            model: {
                template,
            },
        },
    }),
}
