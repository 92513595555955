import { defaultsTo, oneOf } from '../../../utils'

export const general = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT': {
            const eyecatchers =
                action.payload.model.generalConfig
                    ?.eyecatchers
            return {
                ...action.payload.model.generalConfig,
                eyecatchers: {
                    ...eyecatchers,
                    discountDisplayType: defaultsTo(
                        oneOf(
                            eyecatchers?.discountDisplayType,
                            ['prices', 'productImage'],
                        ),
                        'prices',
                    ),
                },
            }
        }
        default:
            return state
    }
}
