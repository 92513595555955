/* istanbul ignore file */
import { noop } from '..'

const isSessionStorageAvailable = (() => {
    let b = undefined
    return () => {
        if (b === undefined) {
            try {
                const storage = window?.['sessionStorage']
                const x = '__storage_test__'
                storage.setItem(x, x)
                storage.removeItem(x)
                b = true
            } catch (e) {
                b = false
            }
        }

        return b
    }
})()

export const getSessionStorage = () => {
    if (isSessionStorageAvailable()) {
        return window.sessionStorage
    }

    return {
        setItem: noop,
        getItem: noop,
    }
}
