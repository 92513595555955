export const standardSizes = [
    '4XS',
    'XXXXS',
    '3XS',
    'XXXS',
    'XXS',
    '2XS',
    'XXS/XS',
    'XS (Normal)',
    'XS',
    'XS/S',
    'XS-S',
    'XS/M',
    'S',
    'S (Kurz)',
    'S (Lang)',
    'S (Normal)',
    'S (Tall)',
    'S-M',
    'S-tall',
    'S/M',
    'S/Slim',
    'M',
    'M (Kurz)',
    'M (Lang)',
    'M (Normal)',
    'M (Tall)',
    'M-L',
    'M-tall',
    'M/L',
    'L',
    'L (Kurz)',
    'L (Lang)',
    'L (Normal)',
    'L-XL',
    'L/XL',
    'L/XL-A',
    'L/XXL',
    'XL',
    'XL (Kurz)',
    'XL (Lang)',
    'XL (Normal)',
    'XL-XLG',
    'XL-XXL',
    'XL/XXL',
    'XXL',
    'XXL (Kurz)',
    'XXL (Lang)',
    'XXL (Normal)',
    '2XL',
    'XXXXXXL',
    'XXL/3XL',
    'XXXL',
    '3XL',
    '3XL (Kurz)',
    '3XL (Lang)',
    '3XL (Normal)',
    '3XL/4XL',
    '4XL',
    '4XL (Normal)',
    'XXXXL',
    '4XL/5XL',
    '5XL',
    'XXXXXL',
    '5XL/6XL',
    '6XL',
    'XXXXXXL',
    '6XL/7XL',
    '7XL',
    'XXXXXXXL',
    '7XL/8XL',
    '8XL',
    'XXXXXXXXL',
    '8XL/9XL',
    '9XL',
    'XXXXXXXXXL',
    '9XL/10XL',
    '10XL',
    'XXXXXXXXXXL',
    '10XL/11XL',
    '11XL',
    'XXXXXXXXXXXL',
    '11XL/12XL',
    '12XL',
    'XXXXXXXXXXXXL',
]
