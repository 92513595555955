/* istanbul ignore file */

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { omitKeys } from '../utils/object'
import isEqual from 'lodash.isequal'

export const useDeepParams = (omit = ['*']) => {
    const currentParams = useParams()
    const [params, setParams] = useState(currentParams)

    useEffect(() => {
        if (
            !isEqual(
                omitKeys(params, omit),
                omitKeys(currentParams, omit),
            )
        ) {
            setParams(currentParams)
        }
    }, [currentParams, params, omit])

    return params
}
