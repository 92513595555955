import isEqual from 'lodash.isequal'
import { useEffect, useRef } from 'react'
import { redirect, openTab } from '../utils'
import { useTracking } from './useTracking'
import { useDeepQuery } from './useQuery'
import { useSelector } from 'react-redux'
import { useIsIOSSafariPrivate } from './useIsIOSSafariPrivate'

const isPartialEqual = (a, b) => {
    // enthält a b ?
    return Object.keys(b).every((key) => {
        return isEqual(a[key], b[key])
    })
}

export const useExternalNavigate = (options = {}) => {
    const [TrackingTypes, track] = useTracking()

    const enhancedNavigate = async (
        target,
        trackingPayload = {},
    ) => {
        if (options.openNewTab) {
            openTab(target)
        }
        await track({
            target,
            ...trackingPayload,
        })

        !options.openNewTab && redirect(target)
    }

    return [enhancedNavigate, TrackingTypes]
}

export const useExternalNavigateReplaceState = (
    isIOSSafariPrivate = false,
) => {
    const [query, setQuery] = useDeepQuery(false)

    const [_, track] = useTracking()
    const _query = useRef()
    const _target = useRef()
    const _trackingPayload = useRef()

    const enhancedNavigate = async (
        target,
        q,
        trackingPayload = {},
    ) => {
        if (isIOSSafariPrivate) {
            await track({
                target,
                ...trackingPayload,
            })
            return redirect(target)
        }
        _query.current = q
        _target.current = target
        _trackingPayload.current = trackingPayload
        setQuery({
            ...query,
            ...q,
        })
    }

    useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        ;(async () => {
            if (
                query &&
                _query.current &&
                isPartialEqual(query, _query.current)
            ) {
                if (_trackingPayload.current?.type) {
                    await track({
                        target: _target.current,
                        ..._trackingPayload.current,
                    })
                }
                _query.current = null
                redirect(_target.current)
            }
        })()
    }, [query, track])

    return enhancedNavigate
}
