import { useSelector } from 'react-redux'
import { useQuery } from './useQuery'

export const useIsIOSSafariPrivate = () => {
    const [query] = useQuery()
    const env = useSelector((state) => state.env)
    const sessionId = useSelector(
        (state) => state.sessionId,
    )

    return (
        (env === 'production' || env === 'staging') &&
        sessionId !== query['cb.sid']
    )
}
