import axios from 'axios'
import queryString from 'query-string'

let api = axios

export const validQueryParams = [
    'offset',
    'max',
    'sort',
    'order',
    'q',
]

// nicht genutzte query params entfernen
export const cleanQueryParams = (query = {}) => {
    const next = validQueryParams.reduce((acc, val) => {
        if (query[val]) {
            acc[val] = query[val]
        }
        return acc
    }, {})

    const q = {
        ...next,
        ...Object.keys(query).reduce((acc, key) => {
            if (key.startsWith('filters')) {
                acc[key] = query[key]
            }
            return acc
        }, {}),
    }

    return q
}

export const getData = async (location, device) => {
    const search = queryString.stringify({
        max: device === 'MOBILE' ? 10 : 20,
        ...location.query,
        format: 'json',
    })

    const relativeUrl = `${location.pathname}?${search}`

    const response = await api.get(relativeUrl)
    return response.data
}

export const postEvent = async (body, wait = false) => {
    const relativeUrl = '/event'
    if (wait) {
        const response = await api.post(relativeUrl, body)
        return response
    } else {
        api.post(relativeUrl, body)
        return
    }
}

export const configureApi = (options) => {
    if (options?.baseUrl) {
        api = axios.create({
            baseURL: options.baseUrl,
        })
    }

    if (!options) {
        api = {
            get: () => Promise.resolve(),
            post: () => Promise.resolve(),
        }
    }
}
