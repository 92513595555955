import React from 'react'
import PropTypes from 'prop-types'
import { Link as GenericLink } from '@conversionbuddy/Layout'

export const Link = ({
    children,
    href,
    onClick,
    ...other
}) => {
    const handleClick = (e) => {
        onClick && onClick(e)
    }

    return (
        <GenericLink
            href={href}
            onClick={handleClick}
            {...other}
        >
            {children}
        </GenericLink>
    )
}

Link.propTypes = {
    children: PropTypes.any.isRequired,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

Link.defaultProps = {
    onClick: (e) => {
        e.preventDefault()
    },
}
