import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import isEqual from 'lodash.isequal'
import { useInternalNavigate } from './useInternalNavigate'

export const useQuery = (replace = false) => {
    const location = useLocation()
    const [navigate, TrackingTypes] = useInternalNavigate()

    const setQuery = (query, type, state = {}, path) => {
        const nextLocation = {
            ...location,
            pathname: path || location.pathname,
            search: `?${queryString.stringify(query)}`,
        }

        const nextState = {
            state: {
                ...location.state,
                ...state,
            },
        }

        navigate(
            nextLocation,
            {
                ...nextState,
                replace,
            },
            type,
        )
    }

    return [
        queryString.parse(location.search),
        setQuery,
        TrackingTypes,
        location,
    ]
}

export const removeOmittedKeysFromQuery = (
    query,
    keys = [],
) => {
    return keys.length === 0
        ? query
        : Object.keys(query).reduce((acc, key) => {
              if (keys.includes(key)) {
                  return acc
              }

              acc[key] = query[key]
              return acc
          }, {})
}

export const useDeepQuery = (replace = false, omitKeys) => {
    const [query, setQuery, TrackingTypes] =
        useQuery(replace)
    const q = removeOmittedKeysFromQuery(query, omitKeys)
    const [deepQuery, setDeepQuery] = useState(q)

    useEffect(() => {
        if (!isEqual(q, deepQuery)) {
            setDeepQuery(q)
        }
    }, [q, deepQuery])

    return [deepQuery, setQuery, TrackingTypes]
}
