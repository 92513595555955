import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { usePrices } from '../../../../../../hooks/usePrices'
import { getStyles } from '@conversionbuddy/Layout'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { SaleEyecatcher } from '../../../../../Layout/Eyecatchers'
import { useSelector } from 'react-redux'

const PriceText = styled.span`
    ${(props) =>
        getStyles([
            'productsGrid.product.price',
            props.sale
                ? 'productsGrid.product.salePrice'
                : '',
        ])(props)}
`

const OldPriceText = styled(PriceText)`
    ${getStyles(['productsGrid.product.oldPrice'])}
`

const BasePriceText = styled.div`
    ${(props) =>
        getStyles([
            'productsGrid.product.basePrice',
            props.sale
                ? 'productsGrid.product.salePrice'
                : '',
        ])(props)}
`

const LowestPriceText = styled.div`
    ${getStyles(['productsGrid.product.lowestPrice'])}
`
const PriceInfoText = styled.div`
    ${getStyles(['productsGrid.product.priceInformation'])}
`

const StyledPrices = styled.div`
    ${getStyles(['productsGrid.product.prices'])}
`

export const ProductPrice = ({ product }) => {
    const {
        basePrice,
        basePriceUnit,
        current,
        discount,
        lowestPrice,
        multiple,
        multiplePrefix,
        old,
        priceInfo,
        pricePrefix,
    } = usePrices(product)

    const discountDisplayType = useSelector(
        (state) =>
            state.general.eyecatchers.discountDisplayType,
    )

    const lowestPriceTranslation = useTranslation(
        'prices.lowestPrice',
    )
    const discountValue =
        discount && (discount * 100).toFixed(0)

    return (
        <StyledPrices>
            <PriceText sale={!!old}>
                {pricePrefix
                    ? `${pricePrefix} `
                    : multiple
                    ? `${multiplePrefix} `
                    : ''}
                {current}
            </PriceText>
            {discountDisplayType === 'prices' ? (
                <SaleEyecatcher
                    discount={discountValue}
                    discountDisplayType={
                        discountDisplayType
                    }
                    multiple={multiple}
                />
            ) : null}
            {old && (
                <OldPriceText>
                    {pricePrefix
                        ? `${pricePrefix} `
                        : multiple
                        ? `${multiplePrefix} `
                        : ''}
                    {old}
                </OldPriceText>
            )}
            {basePrice ? (
                <BasePriceText
                    basePrice
                    sale={!!old}
                >
                    {basePrice} / {basePriceUnit}
                </BasePriceText>
            ) : null}
            {priceInfo ? (
                <PriceInfoText>{priceInfo}</PriceInfoText>
            ) : null}
            {lowestPrice ? (
                <LowestPriceText>
                    {lowestPriceTranslation}: {lowestPrice}
                </LowestPriceText>
            ) : null}
        </StyledPrices>
    )
}

ProductPrice.propTypes = {
    product: PropTypes.object.isRequired,
}
