import { isFilledProductsList } from '../../utils'

export const meta = (state = {}, action) => {
    switch (action.type) {
        case 'SERVER_INIT':
            return {
                total: action.payload.model.total,
                title: action.payload.model.generalConfig
                    .title,
                isFilledProductsList: isFilledProductsList(
                    action.payload.model.products,
                ),
            }
        case 'FETCH_SUCCESS':
            return {
                ...state,
                total: action.payload.model.total,
            }
        default:
            return state
    }
}
