import PropTypes from 'prop-types'
import { getCurrentISODate } from '../../../utils'

const ISOTimestampRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{1,3})?Z$/

export const isIntervalValid = (from, to) => {
    return (
        ISOTimestampRegex.test(from) &&
        ISOTimestampRegex.test(to)
    )
}

export const isWithinInterval = (
    currentTimestamp,
    { from, to },
) => {
    return (
        isIntervalValid(from, to) &&
        from !== to &&
        currentTimestamp >= from &&
        currentTimestamp <= to
    )
}

export const Scheduler = ({ children, from, to }) => {
    return isWithinInterval(getCurrentISODate(), {
        from,
        to,
    })
        ? children
        : null
}

Scheduler.propTypes = {
    children: PropTypes.node.isRequired, // ReactNode
    from: PropTypes.string, // ISO timestamp
    to: PropTypes.string, // ISO timestamp
}

const earliestDate = new Date(0).toISOString()

Scheduler.defaultProps = {
    from: earliestDate,
    to: earliestDate,
}
