import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { renderer } from '../../../utils/react'

import {
    Container,
    Column,
    Row,
    Box,
    Span,
} from '@conversionbuddy/Layout'
import { Logo } from '../Logo'
import { Menu } from '../Menu'
import { Navigation } from '../../Navigation'
import { Search } from '../../Search'
import { CustomStaticLink } from '../StaticLink'

const componentMap = {
    Container: Container,
    Box: Box,
    Fragment: React.Fragment,
    Navigation: Navigation,
    Search: Search,
    Logo: Logo,
    Column: Column,
    Row: Row,
    Menu: Menu,
    CustomStaticLink: CustomStaticLink,
    Span: Span,
}

export const Placeholder = ({ id }) => {
    const template = useSelector(
        (state) => state.template?.placeholders?.[id],
    )
    const el = template
        ? renderer(componentMap, template)
        : null
    return el
}

Placeholder.propTypes = {
    id: PropTypes.string.isRequired,
}
