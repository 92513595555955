/* istanbul ignore file */
import { useRef, useEffect, useState } from 'react'
import { useWindowResize } from './useWindowResize'

export const useDimensions = (once = false, fref) => {
    const windowSize = useWindowResize()
    const iref = useRef({})
    const ref = fref || iref
    const [dimensions, setDimensions] = useState({})

    useEffect(() => {
        if (
            !once ||
            (!dimensions.height && !dimensions.width)
        ) {
            setDimensions({
                width: ref.current?.clientWidth,
                height: ref.current?.clientHeight,
                boundingClientRect:
                    ref.current?.getBoundingClientRect &&
                    ref.current.getBoundingClientRect(),
            })
        }
        // adding once and dimensions leads to infinite loop
        // TODO: fix above
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, windowSize])

    return [ref, dimensions]
}
