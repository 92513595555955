import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getStyles } from '@conversionbuddy/Layout'
import { useExternalNavigate } from '../../../hooks/useExternalNavigate'
import { useSelector } from 'react-redux'

const StyledLogo = styled.div`
    ${(props) =>
        getStyles([
            'logo',
            props.layer ? 'logo.layer' : 'logo.header',
        ])(props)}
`

export const Logo = ({ onClick, ...props }) => {
    const [navigate, TrackingTypes] = useExternalNavigate()
    const homeUrl = useSelector(
        (state) => state.links.home.url,
    )

    const handleClick = () => {
        if (props.layer) {
            return onClick()
        }

        navigate(homeUrl, {
            type: TrackingTypes.CROSS_OUT_INFO,
        })
    }

    return (
        <StyledLogo
            data-testid='logo'
            onClick={handleClick}
            {...props}
        />
    )
}

Logo.propTypes = {
    layer: PropTypes.bool,
    onClick: PropTypes.func,
}

Logo.defaultProps = {
    layer: false,
    onClick: () => {},
}
