const hasPromotion = (product, sku) => {
    if (sku) {
        return sku.promotions?.find(
            (promo) => promo.voucherCode,
        )
    }

    if (!product) {
        return null
    }

    if (!product.skus) {
        return null
    }

    return (product.skus || []).every((sku) =>
        sku.promotions?.find((promo) => promo.voucherCode),
    )
}

export const usePromotion = (product, sku) => {
    if (!hasPromotion(product, sku)) {
        return null
    }

    if (sku) {
        return sku.promotions
    }

    return product.skus[0].promotions
}
