import { bannerBySlots, banners } from './banners'
import { theme } from './theme'
import { translations } from './translations'
import { filters } from './filters'
import { products } from './products'
import { meta } from './meta'
import { links } from './links'
import { config } from './config'
import { headline } from './headline'
import { product } from './product'
import { activeSku } from './activeSku'
import { template } from './template'
import { general } from './general'
import { sorting } from './sorting'
import { tags } from './tags'
import { sessionId } from './sessionId'
import { device } from './device'
import { env } from './env'
import { browser } from './browser'

const reducers = {
    general,
    meta,
    links,
    product,
    products,
    template,
    theme,
    config,
    headline,
    filters,
    translations,
    sorting,
    activeSku,
    sessionId,
    tags,
    banners,
    bannerBySlots,
    device,
    env,
    browser,
}

export default reducers
