import React from 'react'

export const renderer = (
    KeysToComponentMap,
    config,
    key = '',
) => {
    if (typeof config === 'string') {
        return config
    }
    if (
        typeof KeysToComponentMap[config.component] !==
        'undefined'
    ) {
        return React.createElement(
            KeysToComponentMap[config.component],
            {
                ...config.props,
                key,
            },
            config.children &&
                (typeof config.children === 'string'
                    ? config.children
                    : config.children.map((c, i) =>
                          renderer(
                              KeysToComponentMap,
                              c,
                              key
                                  .concat(config.component)
                                  .concat(i),
                          ),
                      )),
        )
    }
}
