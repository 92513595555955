import React from 'react'
import styled from 'styled-components'

import { Box } from '../Box'
import * as Variants from './variants'

const IconBox = ({ dimensions, children }) => {
    const styles = `
        width: ${dimensions[0]}px;
        height: ${dimensions[1]}px;
        display: flex;
    `

    return <Box styles={[styles]}>{children}</Box>
}

export const Icon = ({
    variant,
    dimensions,
    ...svgProps
}) => {
    const Svg = Variants[variant]
    return (
        <IconBox dimensions={dimensions}>
            <Svg {...svgProps} />
        </IconBox>
    )
}
