import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getStyles } from '@conversionbuddy/Layout'
import { inRange } from '../../../../utils'

const StyledThumbnailsControl = styled.div`
    ${(props) =>
        props.position === 'top'
            ? 'top: 20px;'
            : 'bottom: 20px;'}
    position: absolute;
    height: 30px;
    width: 30px;
    ${getStyles(['carousel.control'])}
    transform: rotate(${(props) =>
        props.position === 'top' ? '-90deg' : '90deg'});
    left: calc(50% - 15px);
    background-size: 60%;
    ${(props) =>
        props.visible ? 'display: block' : 'display: none'};
    cursor: pointer;
`

const StyledThumbnail = styled.div`
    background-image: url(${(props) => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    border: 4px solid white;
    border-radius: 2px;
    cursor: pointer;
`

const StyledThumbnailContainer = styled.div`
    width: ${(props) => props.dimensions[2][0]}px;
    min-height: ${(props) => props.dimensions[2][1]}px;
    margin-bottom: 4px;
    transform: translateY(
        ${(props) => props.translation}px
    );
    transition: 0.2s ease all;
    ${(props) =>
        props.active ? 'border: 1px solid #000000;' : ''}
    border-radius: 2px;
`

const StyledThumbnailsContainer = styled.div`
    height: 100%;
    width: 110px;
    align-items: center;
    justify-content: center;
    position: relative;

    display: none;
    @media screen and (min-width: 900px) {
        display: flex;
    }
`

const StyledThumbnails = styled.div`
    display: none;
    position: relative;
    @media screen and (min-width: 900px) {
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: center;
    }
`

// utils
export const createUtils = ({
    activeIndex,
    numberImages,
    thumbnailDimensions,
    thumbnailsPerView,
}) => {
    const isThumbnailVisible = (index) => {
        const max = Math.min(
            numberImages - 1,
            activeIndex + thumbnailsPerView - 1,
        )
        const min = max - thumbnailsPerView + 1

        return inRange(index, min, max)
    }

    const isControlVisible = (position) =>
        numberImages > thumbnailsPerView &&
        !isThumbnailVisible(
            position === 'top' ? 0 : numberImages - 1,
        )

    const calculateTranslation = () => {
        if (numberImages === thumbnailsPerView) {
            return 0
        }

        if (isThumbnailVisible(numberImages - 1)) {
            return -(
                (numberImages - thumbnailsPerView) *
                (thumbnailDimensions[2][1] + 4)
            )
        }

        return -(
            activeIndex *
            (thumbnailDimensions[2][1] + 4)
        )
    }

    return {
        isThumbnailVisible,
        isControlVisible,
        calculateTranslation,
    }
}
export const Thumbnails = ({
    activeIndex,
    height,
    images,
    onChange,
    thumbnailDimensions,
    thumbnailsPerView,
}) => {
    const { calculateTranslation, isControlVisible } =
        createUtils({
            activeIndex,
            numberImages: images.length,
            thumbnailDimensions,
            thumbnailsPerView,
        })

    const handleClickControl = (position) => () => {
        onChange(
            activeIndex + (position === 'top' ? -1 : 1),
        )
    }

    return images.length > 1 ? (
        <StyledThumbnailsContainer data-testid='productLayer.images.thumbnails'>
            <StyledThumbnailsControl
                data-testid='productLayer.images.thumbnails.control'
                position='top'
                visible={isControlVisible('top')}
                onClick={handleClickControl('top')}
            />
            <StyledThumbnailsControl
                data-testid='productLayer.images.thumbnails.control'
                position='bottom'
                visible={isControlVisible('bottom')}
                onClick={handleClickControl('bottom')}
            />
            <StyledThumbnails height={height}>
                {images.map((image, index) => {
                    const handleClick = () =>
                        onChange(index)
                    return (
                        <StyledThumbnailContainer
                            key={`${index}_${image}`}
                            active={activeIndex === index}
                            data-testid='productLayer.images.thumbnails.thumbnail'
                            dimensions={thumbnailDimensions}
                            translation={calculateTranslation()}
                            onClick={handleClick}
                        >
                            <StyledThumbnail
                                image={image}
                            />
                        </StyledThumbnailContainer>
                    )
                })}
            </StyledThumbnails>
        </StyledThumbnailsContainer>
    ) : null
}

Thumbnails.propTypes = {
    images: PropTypes.array.isRequired,
    activeIndex: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    thumbnailDimensions: PropTypes.array.isRequired,
    thumbnailsPerView: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}
