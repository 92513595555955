import styled from 'styled-components'
import {
    mapAdvancedBreakpoints,
    getGutter,
    getStyles,
} from '../../utils'

export const Container = styled.div`
    margin: auto;
    display: block;
    width: 100%;

    ${(props) => getGutter(props.theme, 'padding')}

    ${(props) =>
        mapAdvancedBreakpoints(
            (_, width) => `
    max-width: ${props.fluid ? '100%' : width};
  `,
            props.theme,
        )}

  ${getStyles()}
`
